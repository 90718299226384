import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  deepCopy,
  logger,
  roundWithDecimalPlaces,
  Status,
} from "../services/utils/utils"
import SelectOption from "../models/selectOption"
import { useLazyQuery } from "@apollo/client"
import {
  categoriesList,
  challengesPastList,
  dashboardActivitiesByCategoryList,
  dashboardActivityOverviewList,
  dashboardAverageUserGet,
  dashboardChallengeGet,
  dashboardChallengeLeaderboardGet,
  dashboardCompletedEpisodesList,
  dashboardDailyChallengeOverviewGet,
  dashboardEpisodePerformanceList,
  dashboardFootprintBreakdownByGroupGet,
  dashboardFootprintBreakdownGet,
  dashboardFootprintCategoryBreakdownGet,
  dashboardFootprintOverviewGet,
  dashboardMostActiveUserGet,
  dashboardSavingsGet,
  dashboardTeamCountriesList,
  dashboardTeamGroupList,
  dashboardTopCategoriesList,
  dashboardTopContentList,
  dashboardTopSdgsList,
  dashboardTotalEducationHoursGet,
  dashboardTotalEducationHoursUsersGet,
  dashboardTotalUsersGet,
  sdgsList,
} from "../services/graphql/queries"
import Group from "../models/group"
import Country from "../models/country"
import Challenge from "../models/challenge"
import ChallengeLeaderboardItem from "../models/challengeLeaderboardItem"
import OverviewCardDataItem from "../models/overviewCardDataItem"
import ByCategoryCardDataItem from "../models/byCategoryCardDataItem"
import TeamSavingsCardDataItem from "../models/teamSavingsCardDataItem"
import DashboardCardData from "../models/dashboardCardData"
import FootprintOverviewCardsData from "../models/footprintOverviewCardsData"
import FootprintByGroupCardDataItem from "../models/footprintByGroupCardDataItem"
import SdgItem from "../models/sdgItem"
import CategoryItem from "../models/categoryItem"
import ActionItem from "../models/actionItem"
import CompletedEpisodesCardDataItem from "../models/completedEpisodesCardDataItem"
import Category from "../models/category"
import Sdg from "../models/sdg"
import { AuthContext } from "./auth"
import FootprintBreakdownCardDataItem from "../models/footprintBreakdownCardDataItem"
import FootprintBreakdownByCategoriesDataItem from "../models/footprintBreakdownByCategoriesDataItem"
import { parseFootprintByCategoryData } from "../services/utils/parseFunctions"
import i18next, { t } from "i18next"
import { countriesAlphaCode } from "../services/config/countries"
import User from "../models/user"
import EpisodeItem from "../models/episodeItem"
import ChallengeOverviewDataItem from "../models/challengeOverviewDataItem"
import PastChallenge from "../models/pastChallenge"

// context interface
interface MainContextInterface {
  selectedFilters: SelectOption[]
  setSelectedFilters: Dispatch<SetStateAction<SelectOption[]>>
  dateRange: {
    start: Date | null
    end: Date | null
  }
  setDateRange: Dispatch<
    SetStateAction<{
      start: Date | null
      end: Date | null
    }>
  >
  dataLoading: boolean
  setDataLoading: Dispatch<SetStateAction<boolean>>
  overviewDataLoading: boolean
  groupsList: Group[]
  countriesList: Country[]
  getData: () => void
  getDataFirstTime: (teamId?: string) => void
  dashboardCardData: DashboardCardData | null
  challenge: Challenge | null
  pastChallengesList: PastChallenge[]
  leaderboard: ChallengeLeaderboardItem[]
  leaderboardNextToken: string | null
  educationHoursUsersList: {
    totalEducationHours: number
    user: User
  }[]
  educationHoursUsersListNextToken: string | null
  challengeOverviewData: ChallengeOverviewDataItem[]
  overviewCardData: OverviewCardDataItem[]
  byCategoriesCardData: ByCategoryCardDataItem[]
  teamSavingsCardData: TeamSavingsCardDataItem | null
  footprintOverviewCardsData: FootprintOverviewCardsData | null
  footprintBreakdownCardData: FootprintBreakdownCardDataItem[]
  footprintBreakdownByCategoriesData: FootprintBreakdownByCategoriesDataItem[]
  footprintByGroupCardData: FootprintByGroupCardDataItem[]
  topSdgsList: SdgItem[]
  topCategoriesList: CategoryItem[]
  topEpisodesList: EpisodeItem[]
  topEpisodesListNextToken: string | null
  topActionsList: ActionItem[]
  topActionsListNextToken: string | null
  completedEpisodesCardData: CompletedEpisodesCardDataItem[]
  categories: Category[]
  sdgs: Sdg[]
  loadMoreLeaderboardItems: () => Promise<boolean>
  loadMoreEducationHoursUsersListItems: () => Promise<boolean>
  loadMoreTopEpisodesItems: () => Promise<boolean>
  loadMoreTopActionsItems: () => Promise<boolean>
  error: boolean
  setError: Dispatch<SetStateAction<boolean>>
  errorMessage: string
  getPastChallengeLeaderboard: (
    teamId: string,
    challenge: string,
    nextToken?: string
  ) => Promise<{ items: ChallengeLeaderboardItem[]; nextToken?: string }>
}

const MainContext = createContext<MainContextInterface>({
  selectedFilters: [],
  setSelectedFilters: () => {},
  dateRange: { start: new Date("2024-09-01"), end: new Date() },
  setDateRange: () => {},
  dataLoading: true,
  setDataLoading: () => {},
  overviewDataLoading: true,
  groupsList: [],
  countriesList: [],
  getData: () => {},
  getDataFirstTime: () => {},
  dashboardCardData: null,
  challenge: null,
  pastChallengesList: [],
  leaderboard: [],
  leaderboardNextToken: null,
  educationHoursUsersList: [],
  educationHoursUsersListNextToken: null,
  challengeOverviewData: [],
  overviewCardData: [],
  byCategoriesCardData: [],
  teamSavingsCardData: null,
  footprintOverviewCardsData: null,
  footprintBreakdownCardData: [],
  footprintBreakdownByCategoriesData: [],
  footprintByGroupCardData: [],
  topSdgsList: [],
  topCategoriesList: [],
  topEpisodesList: [],
  topEpisodesListNextToken: null,
  topActionsList: [],
  topActionsListNextToken: null,
  completedEpisodesCardData: [],
  categories: [],
  sdgs: [],
  loadMoreLeaderboardItems: async () => true,
  loadMoreEducationHoursUsersListItems: async () => true,
  loadMoreTopEpisodesItems: async () => true,
  loadMoreTopActionsItems: async () => true,
  error: false,
  setError: () => {},
  errorMessage: "",
  getPastChallengeLeaderboard: async () => {
    return { items: [] }
  },
})

const MainController = ({ children }: { children: ReactNode }) => {
  const { team, isAdmin, isSuperAdmin } = useContext(AuthContext)

  // loadings
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [overviewDataLoading, setOverviewDataLoading] = useState<boolean>(true)

  // states
  const [error, setError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [selectedFilters, setSelectedFilters] = useState<SelectOption[]>([])
  const [dateRange, setDateRange] = useState<{
    start: Date | null
    end: Date | null
  }>(
    isSuperAdmin
      ? { start: null, end: null }
      : isAdmin
      ? { start: new Date("2023-04-20"), end: new Date() }
      : { start: new Date("2024-09-01"), end: new Date() }
  )
  const [groupsList, setGroupsList] = useState<Group[]>([])
  const [countriesList, setCountriesList] = useState<Country[]>([])
  const [dashboardCardData, setDashboardCardData] =
    useState<DashboardCardData | null>(null)
  const [challenge, setChallenge] = useState<Challenge | null>(null)
  const [pastChallengesList, setPastChallengesList] = useState<PastChallenge[]>(
    []
  )
  const [leaderboard, setLeaderboard] = useState<ChallengeLeaderboardItem[]>([])
  const [leaderboardNextToken, setLeaderboardNextToken] = useState<
    string | null
  >(null)
  const [educationHoursUsersList, setEducationHoursUsersList] = useState<
    {
      totalEducationHours: number
      user: User
    }[]
  >([])
  const [
    educationHoursUsersListNextToken,
    setEducationHoursUsersListNextToken,
  ] = useState<string | null>(null)
  const [challengeOverviewData, setChallengeOverviewData] = useState<
    ChallengeOverviewDataItem[]
  >([])
  const [overviewCardData, setOverviewCardData] = useState<
    OverviewCardDataItem[]
  >([])
  const [byCategoriesCardData, setByCategoriesCardData] = useState<
    ByCategoryCardDataItem[]
  >([])
  const [teamSavingsCardData, setTeamSavingsCardData] =
    useState<TeamSavingsCardDataItem | null>(null)
  const [footprintOverviewCardsData, setFootprintOverviewCardsData] =
    useState<FootprintOverviewCardsData | null>(null)
  const [footprintBreakdownCardData, setFootprintBreakdownCardData] = useState<
    FootprintBreakdownCardDataItem[]
  >([])
  const [
    footprintBreakdownByCategoriesData,
    setFootprintBreakdownByCategoriesData,
  ] = useState<FootprintBreakdownByCategoriesDataItem[]>([])
  const [footprintByGroupCardData, setFootprintByGroupCardData] = useState<
    FootprintByGroupCardDataItem[]
  >([])
  const [topSdgsList, setTopSdgsList] = useState<SdgItem[]>([])
  const [topCategoriesList, setTopCategoriesList] = useState<CategoryItem[]>([])
  const [topEpisodesList, setTopEpisodesList] = useState<EpisodeItem[]>([])
  const [topEpisodesListNextToken, setTopEpisodesListNextToken] = useState<
    string | null
  >(null)
  const [topActionsList, setTopActionsList] = useState<ActionItem[]>([])
  const [topActionsListNextToken, setTopActionsListNextToken] = useState<
    string | null
  >(null)
  const [completedEpisodesCardData, setCompletedEpisodesCardData] = useState<
    CompletedEpisodesCardDataItem[]
  >([])
  const [categories, setCategories] = useState<Category[]>([])
  const [sdgs, setSdgs] = useState<Sdg[]>([])

  // queries
  const [categoriesListQuery] = useLazyQuery(categoriesList)
  const [sdgsListQuery] = useLazyQuery(sdgsList)
  const [dashboardTeamGroupListQuery] = useLazyQuery(dashboardTeamGroupList)
  const [dashboardTeamCountriesListQuery] = useLazyQuery(
    dashboardTeamCountriesList
  )
  const [dashboardTotalUsersGetQuery] = useLazyQuery(dashboardTotalUsersGet)
  const [dashboardAverageUserGetQuery] = useLazyQuery(dashboardAverageUserGet)
  const [dashboardMostActiveUserGetQuery] = useLazyQuery(
    dashboardMostActiveUserGet
  )
  const [dashboardChallengeGetQuery] = useLazyQuery(dashboardChallengeGet)
  const [challengesPastListQuery] = useLazyQuery(challengesPastList)
  const [dashboardChallengeLeaderboardGetQuery] = useLazyQuery(
    dashboardChallengeLeaderboardGet
  )
  const [dashboardTotalEducationHoursUsersGetQuery] = useLazyQuery(
    dashboardTotalEducationHoursUsersGet
  )
  const [dashboardDailyChallengeOverviewGetQuery] = useLazyQuery(
    dashboardDailyChallengeOverviewGet
  )
  const [dashboardActivityOverviewListQuery] = useLazyQuery(
    dashboardActivityOverviewList
  )
  const [dashboardActivitiesByCategoryListQuery] = useLazyQuery(
    dashboardActivitiesByCategoryList
  )
  const [dashboardSavingsGetQuery] = useLazyQuery(dashboardSavingsGet)
  const [dashboardFootprintOverviewGetQuery] = useLazyQuery(
    dashboardFootprintOverviewGet
  )
  const [dashboardFootprintBreakdownGetQuery] = useLazyQuery(
    dashboardFootprintBreakdownGet
  )
  const [dashboardFootprintBreakdownByGroupGetQuery] = useLazyQuery(
    dashboardFootprintBreakdownByGroupGet
  )
  const [dashboardTopSdgsListQuery] = useLazyQuery(dashboardTopSdgsList)
  const [dashboardTopCategoriesListQuery] = useLazyQuery(
    dashboardTopCategoriesList
  )
  const [dashboardTopContentListQuery] = useLazyQuery(dashboardTopContentList)
  const [dashboardEpisodePerformanceListQuery] = useLazyQuery(
    dashboardEpisodePerformanceList
  )
  const [dashboardCompletedEpisodesListQuery] = useLazyQuery(
    dashboardCompletedEpisodesList
  )
  const [dashboardTotalEducationHoursGetQuery] = useLazyQuery(
    dashboardTotalEducationHoursGet
  )
  const [dashboardFootprintCategoryBreakdownGetQuery] = useLazyQuery(
    dashboardFootprintCategoryBreakdownGet
  )

  // MAIN
  // get tags lists (categories and sdgs)
  const getTagsLists = async () => {
    try {
      logger(Status.Api, "QUERY categoriesList")
      logger(Status.Api, "QUERY sdgsList")

      const input = {
        lang: i18next.language,
      }

      const result = await Promise.all([
        categoriesListQuery({
          variables: {
            input: input,
          },
        }),
        sdgsListQuery({
          variables: {
            input: input,
          },
        }),
      ])
      logger(
        Status.Info,
        "categories list",
        result[0].data.categoriesList.items
      )
      logger(Status.Info, "sdgs list", result[1].data.sdgsList.items)

      setCategories(result[0].data.categoriesList.items)
      setSdgs(result[1].data.sdgsList.items)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Tags`)

      return false
    }
  }

  // get input for all the queries
  const getInput = (teamId: string) => {
    return {
      teamId: teamId,
      country: selectedFilters
        .filter((filter) => filter.type === "location")
        .map((filter) => filter.id),
      groupId: selectedFilters
        .filter((filter) => filter.type === "group")
        .map((filter) => filter.id),
      startDatetime: dateRange.start
        ? dateRange.start.toISOString().split("T")[0] + "T00:00:00.000Z"
        : null,
      endDatetime: dateRange.end
        ? dateRange.end.toISOString().split("T")[0] + "T23:59:59.000Z"
        : null,
      lang: i18next.language,
    }
  }

  // get current team groups list
  const getTeamGroupsList = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY teamGroupList")
      const { data } = await dashboardTeamGroupListQuery({
        variables: { input: { teamId: teamId, limit: 100 } },
      })
      logger(Status.Info, "team groups list", data.dashboardTeamGroupList.items)

      setGroupsList(
        data.dashboardTeamGroupList.items.map((item: any) => {
          return {
            id: item.groupId,
            name: item.name,
          }
        })
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Groups`)

      return false
    }
  }

  // get current team countries list
  const getTeamCountriesList = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY teamCountriesList")

      let list: any[] = []
      let nextToken
      do {
        const result: any = await dashboardTeamCountriesListQuery({
          variables: {
            input: { teamId: teamId, limit: 100, nextToken: nextToken },
          },
        })
        list = [...list, ...result.data.dashboardTeamCountriesList.items]
        nextToken = result.data.dashboardTeamCountriesList.nextToken
      } while (nextToken)

      logger(Status.Info, "team countries list", list)

      setCountriesList(
        list
          .filter((item: any) => item.country !== "empty")
          .map((item: any) => {
            return {
              id: item.country,
              name: ((countriesAlphaCode as any)[i18next.language] as any)[
                item.country
              ],
            }
          })
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Countries`)

      return false
    }
  }

  // ENGAGEMENT
  // get dashboard card
  const getDashboardCardData = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY totalUsersGet")
      logger(Status.Api, "QUERY totalEducationHoursGet")
      logger(Status.Api, "QUERY averageUserGet")
      logger(Status.Api, "QUERY mostActiveUserGet")

      const input = getInput(teamId)

      const result = await Promise.all([
        dashboardTotalUsersGetQuery({
          variables: { input: input },
        }),
        dashboardTotalEducationHoursGetQuery({
          variables: { input: input },
        }),
        dashboardAverageUserGetQuery({
          variables: { input: input },
        }),
        dashboardMostActiveUserGetQuery({
          variables: { input: input },
        }),
      ])
      const dataToSet: DashboardCardData = {
        totalUsers: result[0].data.dashboardTotalUsersGet
          ? result[0].data.dashboardTotalUsersGet.users
          : null,
        activeUsersPercentage: result[0].data.dashboardTotalUsersGet
          ? roundWithDecimalPlaces(
              result[0].data.dashboardTotalUsersGet.activeUsersPercentage * 100,
              1
            )
          : null,
        totalEducationHours: result[1].data.dashboardTotalEducationHoursGet
          ? Math.round(
              result[1].data.dashboardTotalEducationHoursGet.totalEducationHours
            )
          : null,
        avgEducationHoursPerUser: result[1].data.dashboardTotalEducationHoursGet
          ? roundWithDecimalPlaces(
              result[1].data.dashboardTotalEducationHoursGet
                .avgEducationHoursPerUser,
              2
            )
          : null,
        averageUser: {
          country: result[2].data.dashboardAverageUserGet
            ? result[2].data.dashboardAverageUserGet.country
            : null,
          percentage: result[2].data.dashboardAverageUserGet
            ? roundWithDecimalPlaces(
                result[2].data.dashboardAverageUserGet.percentage * 100,
                1
              )
            : null,
        },
        mostActiveUser: result[3].data.dashboardMostActiveUserGet
          ? {
              sub: result[3].data.dashboardMostActiveUserGet.sub,
              activityHour:
                result[3].data.dashboardMostActiveUserGet.activityHour,
              sameActivityHoursPercentage: roundWithDecimalPlaces(
                result[3].data.dashboardMostActiveUserGet
                  .sameActiveHourPercentage * 100,
                1
              ),
              totalActions:
                result[3].data.dashboardMostActiveUserGet.userActions,
              avgActionsComparisonPercentage: roundWithDecimalPlaces(
                result[3].data.dashboardMostActiveUserGet
                  .avgActionsComparisonPercentage * 100,
                1
              ),
              totalEpisodes:
                result[3].data.dashboardMostActiveUserGet.userEpisodes,
              avgEpisodesComparisonPercentage: roundWithDecimalPlaces(
                result[3].data.dashboardMostActiveUserGet
                  .avgEpisodesComparisonPercentage * 100,
                1
              ),
              user: result[3].data.dashboardMostActiveUserGet.user,
            }
          : {
              sub: null,
              activityHour: null,
              sameActivityHoursPercentage: null,
              totalActions: null,
              avgActionsComparisonPercentage: null,
              totalEpisodes: null,
              avgEpisodesComparisonPercentage: null,
              user: null,
            },
      }
      logger(Status.Info, "dashboard card data", dataToSet)

      setDashboardCardData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Dashboard Card`)
      setDashboardCardData(null)

      return false
    }
  }

  // get challenge card
  const getChallengeCardData = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY challengeGet")
      logger(Status.Api, "QUERY challengeLeaderboardGet")

      const input = getInput(teamId)

      const result = await Promise.all([
        dashboardChallengeGetQuery({
          variables: { input: input },
        }),
        dashboardChallengeLeaderboardGetQuery({
          variables: { input: { ...input, limit: 50 } },
        }),
      ])

      // parse data
      const challengeDataToSet = result[0].data.dashboardChallengeGet
        ? {
            ...result[0].data.dashboardChallengeGet,
            reachedPercentage: roundWithDecimalPlaces(
              result[0].data.dashboardChallengeGet.reachedPercentage * 100,
              1
            ),
            document: result[0].data.dashboardChallengeGet.challenge.document,
            metricName:
              result[0].data.dashboardChallengeGet.challenge.metric.name,
            metricUnit:
              result[0].data.dashboardChallengeGet.challenge.metric.unit,
          }
        : null
      let leaderboardDataToSet = deepCopy(
        result[1].data.dashboardChallengeLeaderboardGet.items
      )
      leaderboardDataToSet = leaderboardDataToSet.filter(
        (item: any) =>
          item.user.first_name !== "deleted" &&
          item.user.last_name !== "deleted"
      )
      leaderboardDataToSet.forEach((item: any) => {
        if (item.userPointsDeltaPercentage) {
          item.userPointsDeltaPercentage = roundWithDecimalPlaces(
            item.userPointsDeltaPercentage * 100,
            1
          )
        }
      })

      logger(Status.Info, "challenge", challengeDataToSet)
      logger(Status.Info, "challenge leaderboard", leaderboardDataToSet)

      setChallenge(challengeDataToSet)
      setLeaderboard(leaderboardDataToSet)
      setLeaderboardNextToken(
        result[1].data.dashboardChallengeLeaderboardGet.nextToken
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Challenge`)
      setChallenge(null)
      setLeaderboard([])
      setLeaderboardNextToken(null)

      return false
    }
  }

  // get past challenges
  const getPastChallengesList = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY challengesPastList")

      const { data } = await challengesPastListQuery({
        variables: { input: { teamId, lang: i18next.language } },
      })

      logger(Status.Info, "past challenges list", data.challengesPastList)

      setPastChallengesList(data.challengesPastList)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Past Challenges`)
      setPastChallengesList([])

      return false
    }
  }

  // get past challenge leaderboard
  const getPastChallengeLeaderboard = async (
    teamId: string,
    challengeId: string,
    nextToken?: string
  ) => {
    try {
      logger(Status.Api, "QUERY challengeLeaderboardGet")

      const { data } = await dashboardChallengeLeaderboardGetQuery({
        variables: {
          input: {
            teamId,
            challengeId,
            lang: i18next.language,
            nextToken,
            limit: 50,
          },
        },
      })

      logger(
        Status.Info,
        `challenge ${challengeId} leaderboard`,
        data.dashboardChallengeLeaderboardGet.items
      )

      return {
        items: data.dashboardChallengeLeaderboardGet
          .items as ChallengeLeaderboardItem[],
        nextToken: data.dashboardChallengeLeaderboardGet.nextToken,
      }
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Past Challenge Leaderboard`)

      return { items: [] }
    }
  }

  // get education hours users list
  const getEducationHoursUsersList = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY totalEducationHoursUsersGet")

      const input = getInput(teamId)

      const { data } = await dashboardTotalEducationHoursUsersGetQuery({
        variables: { input: { ...input, limit: 50 } },
      })

      logger(
        Status.Info,
        "education hours users list",
        data.dashboardTotalEducationHoursUsersGet.items
      )

      setEducationHoursUsersList(
        data.dashboardTotalEducationHoursUsersGet.items
      )
      setEducationHoursUsersListNextToken(
        data.dashboardTotalEducationHoursUsersGet.nextToken
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Education Hours Users List`)
      setEducationHoursUsersList([])
      setEducationHoursUsersListNextToken(null)

      return false
    }
  }

  // get challenge overview card
  const getDailyChallengeOverview = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY dailyChallengeOverviewGet")

      const input = getInput(teamId)
      let nextToken = null
      let dataToSet = []

      do {
        const result: any = await dashboardDailyChallengeOverviewGetQuery({
          variables: {
            input: { ...input, limit: 1000, nextToken: nextToken },
          },
        })

        dataToSet.push(...result.data.dashboardDailyChallengeOverviewGet.items)
        nextToken = result.data.dashboardDailyChallengeOverviewGet.nextToken
      } while (nextToken)

      dataToSet = dataToSet.sort(
        (a, b) =>
          new Date(a.activityDate).getTime() -
          new Date(b.activityDate).getTime()
      )

      logger(Status.Info, "challenge overview data", dataToSet)

      setChallengeOverviewData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Challenge Overview`)
      setChallengeOverviewData([])

      return false
    }
  }

  // get overview overtime card
  const getOverviewCardData = async (teamId: string) => {
    setOverviewDataLoading(true)

    try {
      logger(Status.Api, "QUERY activityOverviewList")

      const input = getInput(teamId)
      let nextToken = null
      const dataToSet = []

      do {
        const result: any = await dashboardActivityOverviewListQuery({
          variables: {
            input: { ...input, limit: 1000, nextToken: nextToken },
          },
        })

        dataToSet.push(...result.data.dashboardActivityOverviewList.items)
        nextToken = result.data.dashboardActivityOverviewList.nextToken
      } while (nextToken)

      logger(Status.Info, "overview overtime card data", dataToSet)

      setOverviewCardData(dataToSet)

      setOverviewDataLoading(false)
    } catch (e) {
      console.log(e)
      setOverviewDataLoading(false)

      setError(true)
      setErrorMessage(`${t("error")} Overview Card`)
      setOverviewCardData([])

      return false
    }
  }

  // get by category card
  const getByCategoryCardData = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY activitiesByCategoryList")

      const input = getInput(teamId)

      const { data } = await dashboardActivitiesByCategoryListQuery({
        variables: { input: { ...input, limit: 100 } },
      })
      logger(
        Status.Info,
        "by cateogory card data",
        data.dashboardActivitiesByCategoryList.items
      )

      setByCategoriesCardData(data.dashboardActivitiesByCategoryList.items)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} By Category Card`)
      setByCategoriesCardData([])

      return false
    }
  }

  // get team savings card
  const getTeamSavingsCardData = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY savingsGet")

      const input = getInput(teamId)

      const { data } = await dashboardSavingsGetQuery({
        variables: { input: input },
      })

      // parse data
      const dataToSet = data.dashboardSavingsGet
        ? {
            bathTubSaving: roundWithDecimalPlaces(
              data.dashboardSavingsGet.bathTubSaving,
              1
            ),
            co2Saving: roundWithDecimalPlaces(
              data.dashboardSavingsGet.co2Saving,
              1
            ),
            dishWasherSaving: roundWithDecimalPlaces(
              data.dashboardSavingsGet.dishWasherSaving,
              1
            ),
            energySaving: roundWithDecimalPlaces(
              data.dashboardSavingsGet.energySaving,
              1
            ),
            phoneChargeSaving: roundWithDecimalPlaces(
              data.dashboardSavingsGet.phoneChargeSaving,
              1
            ),
            waterSaving: roundWithDecimalPlaces(
              data.dashboardSavingsGet.waterSaving,
              1
            ),
          }
        : null
      logger(Status.Info, "team savings card data", dataToSet)

      setTeamSavingsCardData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Savings`)
      setTeamSavingsCardData(null)

      return false
    }
  }

  // FOOTPRINT
  // get footprint overview cards
  const getFootprintOverviewCardsData = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY footprintOverviewGet")

      const input = getInput(teamId)

      const { data } = await dashboardFootprintOverviewGetQuery({
        variables: { input: input },
      })

      // parse data
      const dataToSet = data.dashboardFootprintOverviewGet
        ? {
            ...data.dashboardFootprintOverviewGet,
            avgEmissionAmount: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet.avgEmissionAmount / 1000,
              1
            ),
            maxEmissionAmount: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet.maxEmissionAmount / 1000,
              1
            ),
            minEmissionAmount: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet.minEmissionAmount / 1000,
              1
            ),
            parisAgreementEmissionAmount: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet.parisAgreementEmissionAmount /
                1000,
              1
            ),
            parisAgreementEmissionDeltaPercentage: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet
                .parisAgreementEmissionDeltaPercentage * 100,
              1
            ),
            worldEmissionAmount: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet.worldEmissionAmount / 1000,
              1
            ),
            worldEmissionDeltaPercentage: roundWithDecimalPlaces(
              data.dashboardFootprintOverviewGet.worldEmissionDeltaPercentage *
                100,
              1
            ),
          }
        : null
      logger(Status.Info, "footprint overview cards data", dataToSet)

      setFootprintOverviewCardsData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Footprint`)
      setFootprintOverviewCardsData(null)

      return false
    }
  }

  // get footprint breakdown card
  const getFootprintBreakdownCardData = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY footprintBreakdownGet")

      const input = getInput(teamId)

      const { data } = await dashboardFootprintBreakdownGetQuery({
        variables: { input: input },
      })

      // parse data
      const dataToSet = data.dashboardFootprintBreakdownGet
        ? deepCopy(data.dashboardFootprintBreakdownGet.items)
        : null
      if (dataToSet) {
        dataToSet.forEach((item: FootprintBreakdownCardDataItem) => {
          item.categoryEmissionAmount = roundWithDecimalPlaces(
            item.categoryEmissionAmount / 1000,
            1
          )
          item.categoryEmissionPercentage = roundWithDecimalPlaces(
            item.categoryEmissionPercentage * 100,
            1
          )
          item.totalEmissionAmount = roundWithDecimalPlaces(
            item.totalEmissionAmount / 1000,
            1
          )
        })
      }
      logger(Status.Info, "footprint breakdown card data", dataToSet)

      setFootprintBreakdownCardData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Footprint Breakdown`)
      setFootprintBreakdownCardData([])

      return false
    }
  }

  // get footprint breakdown card by category
  const getFootprintByCategoryData = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY footprintCategoryBreakdownGet")

      const input = getInput(teamId)

      const { data } = await dashboardFootprintCategoryBreakdownGetQuery({
        variables: { input: { ...input, limit: 100 } },
      })

      // parse data
      let dataToSet = []
      if (
        data.dashboardFootprintCategoryBreakdownGet &&
        data.dashboardFootprintCategoryBreakdownGet.items
      ) {
        dataToSet = parseFootprintByCategoryData(
          data.dashboardFootprintCategoryBreakdownGet.items
        )
      }

      logger(Status.Info, "footprint breakdown by category data", dataToSet)

      setFootprintBreakdownByCategoriesData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Footprint Breakdown Detailed`)
      setFootprintBreakdownByCategoriesData([])

      return false
    }
  }

  // get footprint breakdown by group card
  const getFootprintByGroupCardData = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY footprintBreakdownByGroupGet")

      let nextToken = null
      const dataToSet: FootprintByGroupCardDataItem[] = []

      do {
        const input = getInput(teamId)

        const result: any = await dashboardFootprintBreakdownByGroupGetQuery({
          variables: { input: { ...input, limit: 100, nextToken } },
        })

        nextToken = result.data.dashboardFootprintBreakdownByGroupGet.nextToken

        // parse data
        const dataToPush: FootprintByGroupCardDataItem[] = []
        result.data.dashboardFootprintBreakdownByGroupGet.items.forEach(
          (item: any) => {
            dataToPush.push({
              category: item.category,
              categoryEmissionAmount: roundWithDecimalPlaces(
                item.categoryEmissionAmount / 1000,
                1
              ),
              categoryEmissionPercentage: roundWithDecimalPlaces(
                item.categoryEmissionPercentage * 100,
                1
              ),
              groupId: item.groupId,
            })
          }
        )

        dataToSet.push(...dataToPush)
      } while (nextToken)

      logger(Status.Info, "footprint breakdown by group card data", dataToSet)

      setFootprintByGroupCardData(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Footprint By Group`)
      setFootprintByGroupCardData([])

      return false
    }
  }

  // EDUCATION
  // get top cards
  const getTopCardsData = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY topSdgsList")
      logger(Status.Api, "QUERY topCategoriesList")
      logger(Status.Api, "QUERY episodePerformanceList")
      logger(Status.Api, "QUERY topContentList")

      const input = getInput(teamId)

      const result = await Promise.all([
        dashboardTopSdgsListQuery({
          variables: { input: { ...input, limit: 100 } },
        }),
        dashboardTopCategoriesListQuery({
          variables: { input: { ...input, limit: 100 } },
        }),
        dashboardEpisodePerformanceListQuery({
          variables: {
            input: { ...input, limit: 50 },
          },
        }),
        dashboardTopContentListQuery({
          variables: {
            input: { ...input, limit: 50, activityType: "action" },
          },
        }),
      ])
      logger(
        Status.Info,
        "top sdgs list",
        result[0].data.dashboardTopSdgsList.items
      )
      logger(
        Status.Info,
        "top categories list",
        result[1].data.dashboardTopCategoriesList.items
      )
      logger(
        Status.Info,
        "top episodes list",
        result[2].data.dashboardEpisodePerformanceList.items
      )
      logger(
        Status.Info,
        "top actions list",
        result[3].data.dashboardTopContentList.items
      )

      setTopSdgsList(result[0].data.dashboardTopSdgsList.items)
      setTopCategoriesList(result[1].data.dashboardTopCategoriesList.items)
      setTopEpisodesList(result[2].data.dashboardEpisodePerformanceList.items)
      setTopEpisodesListNextToken(
        result[2].data.dashboardEpisodePerformanceList.nextToken
      )
      setTopActionsList(result[3].data.dashboardTopContentList.items)
      setTopActionsListNextToken(
        result[3].data.dashboardTopContentList.nextToken
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} SDGs / Categories / Content`)
      setTopSdgsList([])
      setTopCategoriesList([])
      setTopEpisodesList([])
      setTopEpisodesListNextToken(null)
      setTopActionsList([])
      setTopActionsListNextToken(null)

      return false
    }
  }

  // get completed episodes card
  const getCompletedEpisodesCardData = async (teamId: string) => {
    try {
      logger(Status.Api, "QUERY completedEpisodesList")

      const input = getInput(teamId)

      const { data } = await dashboardCompletedEpisodesListQuery({
        variables: { input: { ...input, limit: 50 } },
      })
      logger(
        Status.Info,
        "completed episodes card data",
        data.dashboardCompletedEpisodesList.items
      )

      setCompletedEpisodesCardData(data.dashboardCompletedEpisodesList.items)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Completed Episodes`)
      setCompletedEpisodesCardData([])

      return false
    }
  }

  // LOAD MORE
  const loadMoreLeaderboardItems = async () => {
    try {
      logger(Status.Api, "QUERY challengeLeaderboardGet")

      const input = getInput(team!.id)

      const { data } = await dashboardChallengeLeaderboardGetQuery({
        variables: {
          input: { ...input, limit: 50, nextToken: leaderboardNextToken },
        },
      })

      // parse data
      let dataToSet = deepCopy(data.dashboardChallengeLeaderboardGet.items)
      dataToSet = dataToSet.filter(
        (item: any) =>
          item.user.first_name !== "deleted" &&
          item.user.last_name !== "deleted"
      )
      dataToSet.forEach((item: any) => {
        if (item.userPointsDeltaPercentage) {
          item.userPointsDeltaPercentage = roundWithDecimalPlaces(
            item.userPointsDeltaPercentage * 100,
            1
          )
        }
      })

      logger(Status.Info, "challenge leaderboard", [
        ...leaderboard,
        ...dataToSet,
      ])

      setLeaderboard([...leaderboard, ...dataToSet])
      setLeaderboardNextToken(data.dashboardChallengeLeaderboardGet.nextToken)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Leaderboard`)

      return false
    }
  }

  const loadMoreEducationHoursUsersListItems = async () => {
    try {
      logger(Status.Api, "QUERY totalEducationHoursUsersGet")

      const input = getInput(team!.id)

      const { data } = await dashboardTotalEducationHoursUsersGetQuery({
        variables: {
          input: {
            ...input,
            limit: 50,
            nextToken: educationHoursUsersListNextToken,
          },
        },
      })

      logger(Status.Info, "education hours users list", [
        ...educationHoursUsersList,
        ...data.dashboardTotalEducationHoursUsersGet.items,
      ])

      setEducationHoursUsersList((current) => [
        ...current,
        ...data.dashboardTotalEducationHoursUsersGet.items,
      ])
      setEducationHoursUsersListNextToken(
        data.dashboardTotalEducationHoursUsersGet.nextToken
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Education Hours Users List`)

      return false
    }
  }

  const loadMoreTopEpisodesItems = async () => {
    try {
      logger(Status.Api, "QUERY episodePerformanceList")

      const input = getInput(team!.id)

      const { data } = await dashboardEpisodePerformanceListQuery({
        variables: {
          input: {
            ...input,
            limit: 50,
            nextToken: topEpisodesListNextToken,
          },
        },
      })
      logger(Status.Info, "top episodes list", [
        ...topEpisodesList,
        ...data.dashboardEpisodePerformanceList.items,
      ])

      setTopEpisodesList([
        ...topEpisodesList,
        ...data.dashboardEpisodePerformanceList.items,
      ])
      setTopEpisodesListNextToken(
        data.dashboardEpisodePerformanceList.nextToken
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Episodes`)

      return false
    }
  }

  const loadMoreTopActionsItems = async () => {
    try {
      logger(Status.Api, "QUERY topContentList")

      const input = getInput(team!.id)

      const { data } = await dashboardTopContentListQuery({
        variables: {
          input: {
            ...input,
            limit: 50,
            activityType: "action",
            nextToken: topActionsListNextToken,
          },
        },
      })
      logger(Status.Info, "top actions list", [
        ...topActionsList,
        ...data.dashboardTopContentList.items,
      ])

      setTopActionsList([
        ...topActionsList,
        ...data.dashboardTopContentList.items,
      ])
      setTopActionsListNextToken(data.dashboardTopContentList.nextToken)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Actions`)

      return false
    }
  }

  // data fetch
  const getDataFirstTime = async (teamId?: string) => {
    setDataLoading(true)

    let teamIdToPass
    if (teamId) {
      teamIdToPass = teamId
    } else {
      teamIdToPass = team?.id
    }

    if (teamIdToPass) {
      getOverviewCardData(teamIdToPass)
      await Promise.all([
        getTagsLists(),
        getTeamGroupsList(teamIdToPass),
        getTeamCountriesList(teamIdToPass),
        getDashboardCardData(teamIdToPass),
        getChallengeCardData(teamIdToPass),
        getPastChallengesList(teamIdToPass),
        getEducationHoursUsersList(teamIdToPass),
        getDailyChallengeOverview(teamIdToPass),
        getByCategoryCardData(teamIdToPass),
        getTeamSavingsCardData(teamIdToPass),
        getFootprintOverviewCardsData(teamIdToPass),
        getFootprintBreakdownCardData(teamIdToPass),
        getFootprintByCategoryData(teamIdToPass),
        getFootprintByGroupCardData(teamIdToPass),
        getTopCardsData(teamIdToPass),
        getCompletedEpisodesCardData(teamIdToPass),
      ])
    }

    setDataLoading(false)
  }

  const getData = async () => {
    setDataLoading(true)

    getOverviewCardData(team!.id)
    await Promise.all([
      getTagsLists(),
      getTeamCountriesList(team!.id),
      getDashboardCardData(team!.id),
      getChallengeCardData(team!.id),
      getPastChallengesList(team!.id),
      getEducationHoursUsersList(team!.id),
      getDailyChallengeOverview(team!.id),
      getByCategoryCardData(team!.id),
      getTeamSavingsCardData(team!.id),
      getFootprintOverviewCardsData(team!.id),
      getFootprintBreakdownCardData(team!.id),
      getFootprintByCategoryData(team!.id),
      getFootprintByGroupCardData(team!.id),
      getTopCardsData(team!.id),
      getCompletedEpisodesCardData(team!.id),
    ])

    setDataLoading(false)
  }

  useEffect(() => {
    getDataFirstTime()
  }, [])

  return (
    <MainContext.Provider
      value={{
        selectedFilters,
        setSelectedFilters,
        dateRange,
        setDateRange,
        dataLoading,
        setDataLoading,
        overviewDataLoading,
        groupsList,
        countriesList,
        getData,
        getDataFirstTime,
        dashboardCardData,
        challenge,
        pastChallengesList,
        leaderboard,
        leaderboardNextToken,
        educationHoursUsersList,
        educationHoursUsersListNextToken,
        challengeOverviewData,
        overviewCardData,
        byCategoriesCardData,
        teamSavingsCardData,
        footprintOverviewCardsData,
        footprintBreakdownCardData,
        footprintBreakdownByCategoriesData,
        footprintByGroupCardData,
        topSdgsList,
        topCategoriesList,
        topEpisodesList,
        topEpisodesListNextToken,
        topActionsList,
        topActionsListNextToken,
        completedEpisodesCardData,
        categories,
        sdgs,
        loadMoreLeaderboardItems,
        loadMoreEducationHoursUsersListItems,
        loadMoreTopEpisodesItems,
        loadMoreTopActionsItems,
        error,
        setError,
        errorMessage,
        getPastChallengeLeaderboard,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}
export { MainController, MainContext }
