import { Skeleton, Stack } from "@mui/material"
import { defaultBoxShadow } from "../../services/config/constants"
import Text from "../global/Text"

const SdgCardSkeleton = () => {
  return (
    <Stack
      direction="row"
      gap={1.5}
      style={{
        width: "100%",
        height: "auto",
        paddingInline: 11,
        paddingTop: 8,
        paddingBottom: 8,
        boxShadow: defaultBoxShadow,
        borderRadius: 8,
      }}
    >
      <Skeleton
        variant="rectangular"
        style={{
          width: 46,
          minWidth: 46,
          height: 46,
          borderRadius: 4,
        }}
      />
      <Stack gap={0.75} style={{ width: "100%" }}>
        <Text
          fontSize={16}
          lineHeight="20px"
          fontWeight={500}
          shouldLoad
          skeletonWidth={150}
        />
        <Text fontSize={14} lineHeight="14px" fontWeight={500} shouldLoad />
      </Stack>
    </Stack>
  )
}

export default SdgCardSkeleton
