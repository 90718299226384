import { Skeleton, Stack } from "@mui/material"
import { defaultBoxShadow } from "../../services/config/constants"

const ContentCardSkeleton = () => {
  return (
    <Stack
      style={{
        width: "100%",
        height: 220,
        borderRadius: 8,
        boxShadow: defaultBoxShadow,
        overflow: "hidden",
      }}
    >
      <Skeleton
        variant="rectangular"
        style={{
          width: "100%",
          height: 95,
          borderRadius: 8,
        }}
      />
      <Stack style={{ paddingInline: 6, marginTop: 8 }}>
        <Skeleton
          style={{
            fontSize: 14,
            lineHeight: "16px",
          }}
        />
        <Skeleton
          style={{
            width: "50%",
            fontSize: 14,
            lineHeight: "16px",
          }}
        />
        <Skeleton
          style={{
            width: "80%",
            fontSize: 12,
            lineHeight: "13px",
            marginTop: 29,
          }}
        />
        <Skeleton
          style={{
            width: "35%",
            fontSize: 12,
            lineHeight: "13px",
          }}
        />
        <Skeleton
          variant="rectangular"
          style={{
            width: 45,
            height: 17,
            borderRadius: 3,
            marginTop: 6,
          }}
        />
      </Stack>
    </Stack>
  )
}

export default ContentCardSkeleton
