export const colors = {
  primary: "#1B8FFD", // brand blue + 20% white
  primaryLogo: "#0074E4",
  secondary: "#D02963", // same as missions => act
  disabled: "#7EC0FF", // brand blue + 50% white
  background: "#F2F8FE", // brand blue + 95% white
  backgroundLight: "#F2F3F4", // Japanese Indigo + 95% white
  backgroundWhite: "#FFFFFF", // 100% white
  stroke: "#7D8D96", // Japanese Indigo + 40% white
  text: "#274150", // Japanese Indigo
  textSecondary: "#7D8D96", // Japanese Indigo + 20% white (lighter)
  textWhite: "#FFFFFF", // 100% white
  success: "#148548",
  error: "#D02929",
  chartsGrid: "#EBECED",
  categories: {
    home: "#FFE8B4",
    homeText: "#664F1B",
    shopping: "#FFBAD7",
    shoppingText: "#66213E",
    transport: "#FDCFAD",
    transportText: "#643614",
    food: "#B1E9DF",
    foodText: "#185046",
    commuting: "#C0BAFF",
    businessTravel: "#BAD7FF",
    homeWorking: "#D6ECA0",
    episodes: "#8CC2FA",
    actions: "#337DC6",
  },
  esgs: {
    environment: "#B4E9FF",
    environmentText: "#485D66",
    society: "#E7B4FF",
    societyText: "#5C4866",
    governance: "#FFB4B4",
    governanceText: "#664848",
  },
}
