import { ButtonBase, Stack } from "@mui/material"
import Filter from "./Filter"
import { FilterType } from "../../services/config/enum"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import { defaultTransition } from "../../services/config/constants"
import Title from "./Title"
import { colors } from "../../services/config/colors"
import { t } from "i18next"

const CompactFilters = () => {
  const { selectedFilters, setSelectedFilters, dataLoading, getData } =
    useContext(MainContext)

  return (
    <Stack
      direction="row"
      gap={2}
      style={{ width: "100%", height: 35, marginTop: 40 }}
    >
      <Filter
        type={FilterType.location}
        selectedOptions={selectedFilters}
        setSelectedOptions={setSelectedFilters}
        variant="compact"
      />
      <Filter
        type={FilterType.group}
        selectedOptions={selectedFilters}
        setSelectedOptions={setSelectedFilters}
        variant="compact"
      />
      <ButtonBase
        disabled={dataLoading || !selectedFilters.length}
        disableRipple
        style={{
          borderRadius: 4,
          transition: defaultTransition,
          opacity: selectedFilters.length ? 1 : 0,
        }}
        onClick={() => {
          selectedFilters.splice(0, selectedFilters.length)
          setSelectedFilters([...selectedFilters])
          getData()
        }}
      >
        <Title
          fontSize={14}
          lineHeight="17px"
          color={colors.primary}
          style={{
            transition: defaultTransition,
            opacity: dataLoading ? 0.4 : 1,
          }}
        >
          {t("clear_all")}
        </Title>
      </ButtonBase>
    </Stack>
  )
}

export default CompactFilters
