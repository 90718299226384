import { CSSProperties, ReactNode } from "react"
import { motion, useReducedMotion } from "framer-motion"

const FadeFromTop = ({
  children,
  style,
  delay = 0,
}: {
  children?: ReactNode
  style?: CSSProperties
  delay?: number
}) => {
  const shouldReduceMotion = useReducedMotion()

  return (
    <motion.div
      style={style}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        y: {
          delay: shouldReduceMotion ? 0 : delay,
          type: "spring",
          duration: 0.4,
        },
        opacity: {
          delay: shouldReduceMotion ? 0 : delay,
          type: "spring",
          duration: 0.6,
        },
      }}
    >
      {children}
    </motion.div>
  )
}

export default FadeFromTop
