import { Stack } from "@mui/material"
import InfoCard from "../global/InfoCard"
import { colors } from "../../services/config/colors"
import Title from "../global/Title"
import Text from "../global/Text"
import {
  formatNumber,
  roundWithDecimalPlaces,
} from "../../services/utils/utils"
import { CSSProperties } from "react"

const TeamSavingsCard = ({
  icon1,
  value1,
  label1,
  icon2,
  value2,
  label2,
  style,
}: {
  icon1: string
  value1: number
  label1: string
  icon2: string
  value2: number
  label2: string
  style?: CSSProperties
}) => {
  return (
    <InfoCard
      size="small"
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        ...style,
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        style={{
          width: "100%",
          paddingInline: 8,
          paddingTop: 8,
          paddingBottom: 8,
          gap: 24,
        }}
      >
        <Stack gap={1} style={{ width: "50%" }}>
          <Stack direction="row" alignItems="center" gap={1}>
            <div
              className="center"
              style={{
                width: 38,
                minWidth: 38,
                height: 38,
                borderRadius: 7,
                backgroundColor: colors.primary,
              }}
            >
              <img src={icon1} alt="" />
            </div>
            {value1 ? (
              <Title fontSize={16} shouldLoad skeletonWidth={40}>
                {value1 >= 1000000
                  ? formatNumber(roundWithDecimalPlaces(value1 / 1000000, 1))
                  : value1 >= 10000
                  ? formatNumber(roundWithDecimalPlaces(value1 / 1000, 1))
                  : formatNumber(Math.round(value1))}
                {value1 >= 1000000 ? "M" : value1 >= 10000 ? "K" : null}
              </Title>
            ) : (
              <Title fontSize={16} shouldLoad skeletonWidth={40}>
                --
              </Title>
            )}
          </Stack>
          <Text
            fontSize={11}
            lineHeight="14px"
            fontWeight={500}
            color={colors.textSecondary}
            shouldLoad
          >
            {label1}
          </Text>
        </Stack>
        <Title
          fontSize={16}
          color={colors.primary}
          style={{ alignSelf: "center" }}
        >
          =
        </Title>
        <Stack gap={1} style={{ width: "50%" }}>
          <Stack direction="row" alignItems="center" gap={1}>
            <div
              className="center"
              style={{
                width: 38,
                minWidth: 38,
                height: 38,
                borderRadius: 7,
                backgroundColor: colors.primary,
              }}
            >
              <img src={icon2} alt="" />
            </div>
            {value2 ? (
              <Title fontSize={16} shouldLoad skeletonWidth={40}>
                {value2 >= 1000000
                  ? formatNumber(roundWithDecimalPlaces(value2 / 1000000, 1))
                  : value2 >= 10000
                  ? formatNumber(roundWithDecimalPlaces(value2 / 1000, 1))
                  : formatNumber(Math.round(value2))}
                {value2 >= 1000000 ? "M" : value2 >= 10000 ? "K" : null}
              </Title>
            ) : (
              <Title fontSize={16} shouldLoad skeletonWidth={40}>
                --
              </Title>
            )}
          </Stack>
          <Text
            fontSize={11}
            lineHeight="14px"
            fontWeight={500}
            color={colors.textSecondary}
            shouldLoad
          >
            {label2}
          </Text>
        </Stack>
      </Stack>
    </InfoCard>
  )
}

export default TeamSavingsCard
