import { CSSProperties, ChangeEvent } from "react"
import "../../styles/input.css"

const Input = ({
  value,
  onChange,
  placeholder,
  disabled = false,
  style,
}: {
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  disabled: boolean
  style?: CSSProperties
}) => {
  return (
    <input
      className={disabled ? "custom-input-disabled" : "custom-input"}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={onChange}
      style={{
        width: "100%",
        height: 56,
        paddingInline: 12,
        borderRadius: 12,
        fontSize: 14,
        fontWeight: 500,
        ...style,
      }}
    />
  )
}

export default Input
