import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import Route from "../../models/route"
import { useContext, useMemo } from "react"
import Title from "../global/Title"
import { defaultTransition } from "../../services/config/constants"
import { useNavigate } from "react-router-dom"
import { t } from "i18next"
import { AuthContext } from "../../controllers/auth"

const MenuItem = ({ route }: { route: Route }) => {
  const navigate = useNavigate()
  const { setModalMenuOpen } = useContext(AuthContext)

  // is selected or not
  const selected = useMemo(() => {
    if (window.location.pathname.startsWith(route.path)) {
      return true
    }

    return false
  }, [window.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ButtonBase
      style={{
        width: "100%",
        height: 64,
        minHeight: 64,
        borderRadius: 8,
        backgroundColor: selected ? colors.background : colors.backgroundWhite,
        transition: defaultTransition,
      }}
      onClick={() => {
        navigate(route.path)
        setModalMenuOpen(false)
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        style={{ width: "100%", paddingInline: 24 }}
      >
        <div
          className="center"
          style={{
            width: 32,
            height: 32,
            borderRadius: 8,
            backgroundColor: selected ? colors.primary : colors.background,
            transition: defaultTransition,
          }}
        >
          <img src={selected ? route.iconSelected : route.iconDeselected} />
        </div>
        <Title
          fontSize={16}
          color={selected ? colors.text : colors.textSecondary}
          style={{ transition: defaultTransition }}
        >
          {t(route.name)}
        </Title>
      </Stack>
    </ButtonBase>
  )
}

export default MenuItem
