import { Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { defaultBoxShadow } from "../../services/config/constants"
import Title from "../global/Title"
import Text from "../global/Text"
import File from "../../models/file"
import Button from "../global/Button"
import { t } from "i18next"

const ResourceCard = ({ file }: { file: File }) => {
  return (
    <Stack
      style={{
        width: "100%",
        height: "100%",
        padding: 16,
        backgroundColor: colors.backgroundWhite,
        boxShadow: defaultBoxShadow,
        borderRadius: 16,
      }}
    >
      <iframe
        src={`${file.uri}/preview`}
        style={{
          width: "100%",
          height: 173,
          borderRadius: 8,
          border: "none",
        }}
      />
      <Title fontSize={16} ellipsis style={{ marginTop: 14 }}>
        {file.title}
      </Title>
      <Text
        fontSize={14}
        lineHeight="16.8px"
        fontWeight={500}
        color={colors.textSecondary}
        style={{ marginTop: 8 }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </Text>
      <Button
        size="normal"
        primary
        style={{ marginTop: 16 }}
        onClick={() => {
          window.open(file.uri)
        }}
      >
        {t("download")}
      </Button>
    </Stack>
  )
}

export default ResourceCard
