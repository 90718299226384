import { TableRow as Row } from "@mui/material"
import { colors } from "../../services/config/colors"
import { ReactNode } from "react"

const TableRow = ({
  index,
  children,
}: {
  index: number
  children?: ReactNode
}) => {
  return (
    <Row
      key={index}
      style={{
        backgroundColor: index % 2 === 0 ? "#F2F3F4" : colors.backgroundWhite,
      }}
      sx={{ "td, th": { border: 0 } }}
    >
      {children}
    </Row>
  )
}

export default TableRow
