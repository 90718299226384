import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BarElement,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { colors } from "../../services/config/colors"
import ChartDataItem from "../../models/chartDataItem"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import { Skeleton } from "@mui/material"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip
)

const BarChart = ({
  data,
  xTicksFontSize = 10,
  xTicksFontWeight = 700,
  maxBarThickness = 65,
  maxRotation,
}: {
  data: ChartDataItem[]
  xTicksFontSize?: number
  xTicksFontWeight?: 400 | 500 | 600 | 700
  maxBarThickness?: number
  maxRotation?: number
}) => {
  const { dataLoading } = useContext(MainContext)

  // data for chart
  const chartData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        label: "",
        data: data.map((item) => item.value),
        maxBarThickness: maxBarThickness,
        backgroundColor: "#7EC0FF",
        borderColor: data.map((item) => item.backgroundColor),
        colors: data.some((item) => !item.color)
          ? data.map((item) => colors.text)
          : data.map((item) => item.color),
      },
    ],
  }

  return dataLoading ? (
    <Skeleton variant="rounded" style={{ width: "100%", height: "100%" }} />
  ) : (
    <Bar
      style={{ maxWidth: "100%" }}
      options={{
        interaction: {
          mode: "index",
          intersect: false,
        },
        scales: {
          x: {
            border: {
              color: colors.chartsGrid,
            },
            grid: {
              display: false,
            },
            ticks: {
              color: (value) => {
                return chartData.datasets[0].colors[value.index]
              },
              font: {
                family: "Satoshi",
                size: xTicksFontSize,
                weight: xTicksFontWeight,
              },
              backdropColor: (value) => {
                return chartData.datasets[0].borderColor[value.index]
              },
              backdropPadding: data.some((item) => !item.backgroundColor)
                ? 0
                : 5,
              showLabelBackdrop: !data.some((item) => !item.backgroundColor),
              maxRotation: maxRotation,
              padding: 8,
            },
          },
          y: {
            min: 0,
            border: {
              width: 0,
            },
            grid: {
              color: colors.chartsGrid,
              tickWidth: 0,
            },
            ticks: {
              color: colors.text,
              font: {
                family: "Satoshi",
                size: 14,
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          bar: {
            borderRadius: 6,
            hoverBackgroundColor: "#7EC0FF",
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            enabled: true,
          },
        },
      }}
      data={chartData}
    />
  )
}

export default BarChart
