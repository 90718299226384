import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import Title from "../global/Title"
import { defaultTransition } from "../../services/config/constants"
import logoutPrimaryIcon from "../../assets/icons/logout-primary.svg"

const MenuItemButton = ({
  title,
  onClick,
}: {
  title: string
  onClick: () => void
}) => {
  return (
    <ButtonBase
      style={{
        width: "100%",
        height: 64,
        minHeight: 64,
        borderRadius: 8,
        backgroundColor: colors.backgroundWhite,
        transition: defaultTransition,
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        style={{ width: "100%", paddingInline: 24 }}
      >
        <div
          className="center"
          style={{
            width: 32,
            height: 32,
            borderRadius: 8,
            backgroundColor: colors.background,
            transition: defaultTransition,
          }}
        >
          <img src={logoutPrimaryIcon} />
        </div>
        <Title
          fontSize={16}
          color={colors.textSecondary}
          style={{ transition: defaultTransition }}
        >
          {title}
        </Title>
      </Stack>
    </ButtonBase>
  )
}

export default MenuItemButton
