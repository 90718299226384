import { Stack } from "@mui/material"
import sdg1Image from "../../assets/images/sdgs/sdg-1.png"
import sdg2Image from "../../assets/images/sdgs/sdg-2.png"
import sdg3Image from "../../assets/images/sdgs/sdg-3.png"
import sdg4Image from "../../assets/images/sdgs/sdg-4.png"
import sdg5Image from "../../assets/images/sdgs/sdg-5.png"
import sdg6Image from "../../assets/images/sdgs/sdg-6.png"
import sdg7Image from "../../assets/images/sdgs/sdg-7.png"
import sdg8Image from "../../assets/images/sdgs/sdg-8.png"
import sdg9Image from "../../assets/images/sdgs/sdg-9.png"
import sdg10Image from "../../assets/images/sdgs/sdg-10.png"
import sdg11Image from "../../assets/images/sdgs/sdg-11.png"
import sdg12Image from "../../assets/images/sdgs/sdg-12.png"
import sdg13Image from "../../assets/images/sdgs/sdg-13.png"
import sdg14Image from "../../assets/images/sdgs/sdg-14.png"
import sdg15Image from "../../assets/images/sdgs/sdg-15.png"
import sdg16Image from "../../assets/images/sdgs/sdg-16.png"
import sdg17Image from "../../assets/images/sdgs/sdg-17.png"

const SdgsDistributionChartXTicks = () => {
  return (
    <Stack
      direction="row"
      style={{ width: "100%", height: 65, paddingLeft: 50, marginTop: -12 }}
    >
      <TickItem image={sdg1Image} color="#E5243B" />
      <TickItem image={sdg2Image} color="#DDA83A" />
      <TickItem image={sdg3Image} color="#4C9F38" />
      <TickItem image={sdg4Image} color="#C5192D" />
      <TickItem image={sdg5Image} color="#FF3A21" />
      <TickItem image={sdg6Image} color="#26BDE2" />
      <TickItem image={sdg7Image} color="#FCC30B" />
      <TickItem image={sdg8Image} color="#A21942" />
      <TickItem image={sdg9Image} color="#FD6925" />
      <TickItem image={sdg10Image} color="#DD1367" />
      <TickItem image={sdg11Image} color="#FD9D24" />
      <TickItem image={sdg12Image} color="#BF8B2E" />
      <TickItem image={sdg13Image} color="#3F7E44" />
      <TickItem image={sdg14Image} color="#0A97D9" />
      <TickItem image={sdg15Image} color="#56C02B" />
      <TickItem image={sdg16Image} color="#00689D" />
      <TickItem image={sdg17Image} color="#19486A" />
    </Stack>
  )
}

const TickItem = ({ image, color }: { image: string; color: string }) => {
  return (
    <Stack style={{ width: "100%", height: "100%" }}>
      <img
        src={image}
        style={{
          maxWidth: "100%",
          height: 58,
          objectFit: "cover",
          objectPosition: "center left",
        }}
        alt=""
      />
      <div style={{ width: "100%", height: 7, backgroundColor: color }} />
    </Stack>
  )
}

export default SdgsDistributionChartXTicks
