import SelectOption from "../../models/selectOption"

// boolean
export const loggerEnabled = true
export const showPageContainerIcons = false

// config
export const availableLanguages: SelectOption[] = [
  { id: "it", label: "italian" },
  { id: "en", label: "english" },
]
export const defaultTransition = "200ms"
export const defaultBoxShadow = "0px 0px 24px 0px rgba(17, 67, 97, 0.08)"
