import { colors } from "../../services/config/colors"
import Button from "./Button"
import Text from "./Text"
import Title from "./Title"
import { Fade, Modal } from "@mui/material"

const Alert = ({
  open,
  title,
  description,
  primaryActionLabel,
  primaryActionOnClick,
  secondaryActionLabel,
  secondaryActionOnClick,
  loading,
}: {
  open: boolean
  title: string
  description: string
  primaryActionLabel: string
  primaryActionOnClick: () => void
  secondaryActionLabel?: string
  secondaryActionOnClick?: () => void
  loading?: boolean
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby={title}
      aria-describedby={description}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <div
          style={{
            backgroundColor: colors.backgroundWhite,
            width: "calc(100% - 44px)",
            maxWidth: 375,
            height: "auto",
            borderRadius: 16,
            padding: 24,
          }}
        >
          <Title
            fontSize={26}
            lineHeight="30.4px"
            paddingLeft={10}
            paddingRight={10}
            color={colors.text}
            textAlign="center"
            id={title}
          >
            {title}
          </Title>
          <Text
            fontSize={18}
            fontWeight={400}
            lineHeight="24px"
            style={{ marginTop: 16 }}
            color={colors.text}
            textAlign="center"
            id={description}
          >
            {description}
          </Text>
          <Button
            primary
            size="normal"
            style={{ width: "100%", marginTop: 24 }}
            onClick={primaryActionOnClick}
            disabled={loading}
            ariaLabel={primaryActionLabel}
            shouldLoad={false}
          >
            {primaryActionLabel}
          </Button>
          {secondaryActionLabel && (
            <Button
              size="normal"
              style={{ width: "100%", marginTop: 12 }}
              onClick={secondaryActionOnClick}
              loading={loading}
              ariaLabel={secondaryActionLabel}
              shouldLoad={false}
              withBorder
              withShadow={false}
            >
              {secondaryActionLabel}
            </Button>
          )}
        </div>
      </Fade>
    </Modal>
  )
}

export default Alert
