import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { motion } from "framer-motion"
import { CSSProperties, Dispatch, SetStateAction } from "react"
import Title from "./Title"
import { defaultTransition } from "../../services/config/constants"

const Tabs = ({
  tabs,
  selectedTab,
  setSelectedTab,
  disabled,
  variant = "normal",
  style,
}: {
  tabs: string[]
  selectedTab: number
  setSelectedTab: Dispatch<SetStateAction<number>>
  disabled?: boolean
  variant?: "normal" | "slim"
  style?: CSSProperties
}) => {
  return variant === "normal" ? (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        width: "100%",
        height: 40,
        minHeight: 40,
        backgroundColor: colors.backgroundLight,
        borderRadius: 16,
        position: "relative",
        ...style,
      }}
    >
      <motion.div
        animate={selectedTab.toString()}
        variants={tabs.reduce(
          (obj, current, index) => ({
            ...obj,
            [index]: {
              x: `calc(100% * ${index})`,
            },
          }),
          {}
        )}
        transition={{ x: { type: "spring", damping: 19, stiffness: 160 } }}
        initial={false}
        className="center"
        style={{
          width: `calc(100% / ${tabs.length})`,
          height: "100%",
          position: "absolute",
          backgroundColor: "rgba(0, 0, 0, 0)",
          borderRadius: 16,
        }}
      >
        <div
          style={{
            width: "calc(100% - 4px)",
            height: "calc(100% - 4px)",
            backgroundColor: colors.primary,
            borderRadius: 16,
          }}
        />
      </motion.div>
      {tabs.map((tab, index) => (
        <ButtonBase
          key={index}
          disabled={disabled}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 16,
            position: "relative",
          }}
          onClick={() => {
            setSelectedTab(index)
          }}
        >
          <Title
            fontSize={14}
            color={
              selectedTab === index ? colors.textWhite : colors.textSecondary
            }
            style={{
              transition: defaultTransition,
              opacity: disabled ? 0.4 : 1,
            }}
          >
            {tab}
          </Title>
        </ButtonBase>
      ))}
    </Stack>
  ) : (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        width: "100%",
        height: 32,
        minHeight: 32,
        position: "relative",
        ...style,
      }}
    >
      <div
        style={{
          width: "100%",
          height: 1,
          position: "absolute",
          bottom: 1,
          backgroundColor: colors.textSecondary,
          borderRadius: 2,
        }}
      />
      <motion.div
        animate={selectedTab.toString()}
        variants={tabs.reduce(
          (obj, current, index) => ({
            ...obj,
            [index]: {
              x: 140 * index,
            },
          }),
          {}
        )}
        transition={{ x: { type: "spring", damping: 19, stiffness: 160 } }}
        initial={false}
        className="center"
        style={{
          width: 140,
          height: 3,
          position: "absolute",
          bottom: 0,
          backgroundColor: colors.primary,
          borderRadius: 16,
        }}
      />
      {tabs.map((tab, index) => (
        <ButtonBase
          key={index}
          style={{
            width: 140,
            height: "100%",
            position: "relative",
            alignItems: "flex-start",
          }}
          disableRipple
          onClick={() => {
            setSelectedTab(index)
          }}
        >
          <Title
            fontSize={16}
            lineHeight="19px"
            color={
              selectedTab === index ? colors.primary : colors.textSecondary
            }
            style={{ transition: defaultTransition }}
          >
            {tab}
          </Title>
        </ButtonBase>
      ))}
    </Stack>
  )
}

export default Tabs
