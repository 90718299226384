import { CSSProperties, ReactNode, useContext } from "react"
import { colors } from "../../services/config/colors"
import { Skeleton, useMediaQuery, useTheme } from "@mui/material"
import { MainContext } from "../../controllers/main"

const Text = ({
  children,
  fontSize = 16,
  fontWeight = 400,
  lineHeight,
  color = colors.text,
  width,
  textAlign = "left",
  style,
  paddingLeft = 0,
  paddingRight = 0,
  ellipsis = false,
  ariaHidden,
  skeletonWidth = 80,
  shouldLoad,
  id,
}: {
  children?: ReactNode
  fontSize?: number
  fontWeight?: 300 | 400 | 500 | 600 | 700 | 800 | 900
  lineHeight?: string
  color?: string
  width?: string | number
  textAlign?: "left" | "center" | "right"
  style?: CSSProperties
  paddingLeft?: number
  paddingRight?: number
  ellipsis?: boolean
  ariaHidden?: boolean
  skeletonWidth?: number
  shouldLoad?: boolean
  id?: string
}) => {
  const theme = useTheme()
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  const { dataLoading } = useContext(MainContext)

  return dataLoading && shouldLoad ? (
    <Skeleton
      variant="text"
      style={{
        width: skeletonWidth,
        fontSize: matchesMD ? fontSize - 2 : fontSize,
        lineHeight: lineHeight,
        ...style,
      }}
    />
  ) : (
    <p
      id={id}
      style={{
        fontSize: matchesMD ? fontSize - 2 : fontSize,
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        color: color,
        maxWidth: width,
        textAlign: textAlign,
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        whiteSpace: ellipsis ? "nowrap" : undefined,
        overflow: ellipsis ? "hidden" : undefined,
        textOverflow: ellipsis ? "ellipsis" : undefined,
        ...style,
      }}
      aria-hidden={ariaHidden ? "true" : "false"}
    >
      {children}
    </p>
  )
}

export default Text
