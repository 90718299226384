import cookingIcon from "../../assets/icons/footprint/home/cooking.svg"
import electricityIcon from "../../assets/icons/footprint/home/electricity.svg"
import heatingIcon from "../../assets/icons/footprint/home/heating.svg"
import wasteIcon from "../../assets/icons/footprint/home/waste.svg"
import waterIcon from "../../assets/icons/footprint/home/water.svg"
import appliancesIcon from "../../assets/icons/footprint/shopping/appliances.svg"
import beautyIcon from "../../assets/icons/footprint/shopping/beauty.svg"
import clothesIcon from "../../assets/icons/footprint/shopping/clothes.svg"
import deliveriesIcon from "../../assets/icons/footprint/shopping/deliveries.svg"
import eventsIcon from "../../assets/icons/footprint/shopping/events.svg"
import furnitureIcon from "../../assets/icons/footprint/shopping/furniture.svg"
import carsIcon from "../../assets/icons/footprint/transport/cars.svg"
import flightsIcon from "../../assets/icons/footprint/transport/flights.svg"
import motorbikeIcon from "../../assets/icons/footprint/transport/motorbike.svg"
import publicTransportIcon from "../../assets/icons/footprint/transport/public-transport.svg"
import taxiIcon from "../../assets/icons/footprint/transport/taxi.svg"
import trainsIcon from "../../assets/icons/footprint/transport/trains.svg"
import hotelIcon from "../../assets/icons/footprint/transport/hotel.png"

const footprintImages = {
  heating: heatingIcon,
  cooking: cookingIcon,
  water: waterIcon,
  powerConsumption: electricityIcon,
  waste: wasteIcon,
  appliances: appliancesIcon,
  hairsalon: beautyIcon,
  clothes: clothesIcon,
  homeDelivery: deliveriesIcon,
  sportAndCultural: eventsIcon,
  furniture: furnitureIcon,
  car: carsIcon,
  flights: flightsIcon,
  motorbike: motorbikeIcon,
  commute: publicTransportIcon,
  taxi: taxiIcon,
  train: trainsIcon,
  hotel: hotelIcon,
}

export default footprintImages
