import { Stack } from "@mui/material"
import { CSSProperties } from "react"
import { colors } from "../../services/config/colors"
import { t } from "i18next"
import Text from "./Text"
import { FallbackProps } from "react-error-boundary"

const CardFallback = ({
  props,
  width,
  style,
}: {
  props: FallbackProps
  width?: string
  style?: CSSProperties
}) => {
  return (
    <Stack
      className="center"
      gap={3}
      style={{
        width: width ?? "100%",
        minWidth: width ?? "100%",
        height: "auto",
        borderRadius: 16,
        backgroundColor: colors.backgroundWhite,
        padding: 24,
        position: "relative",
        ...style,
      }}
    >
      <Text textAlign="center" fontWeight={500}>
        {t("something_went_wrong")}
      </Text>
      <code>
        {props.error.stack.slice(0, props.error.stack.indexOf("http") - 2)}
      </code>
    </Stack>
  )
}

export default CardFallback
