import i18next, { t } from "i18next"
import PageContainer from "../../../components/global/PageContainer"
import Title from "../../../components/global/Title"
import ResourceCard from "../../../components/library/ResourceCard"
import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import File from "../../../models/file"
import FadeFromTop from "../../../components/animations/FadeFromTop"

const MailFlowStorytelling = () => {
  // array of files
  const [files, setFiles] = useState<File[]>([
    {
      uri:
        i18next.language === "it"
          ? "https://docs.google.com/document/d/1voSdE68eFNa6WHaHzDNE2aQ6Y5mvtzs-RLLOG7MCKMA"
          : "https://docs.google.com/document/d/1tQZXrQN8J9fgrqV3yVPaBgXlir47OeV_",
      title: t("team_launch"),
    },
    {
      uri:
        i18next.language === "it"
          ? "https://docs.google.com/document/d/1U5lhDGMB61roElSCnf1dRWYSXd2inoy9gdjlbta0Zqg"
          : "https://docs.google.com/document/d/1cGOC-pPyyG82TH1GPBynWASsL2iF9SwV",
      title: t("challenge_launch_group"),
    },
    {
      uri:
        i18next.language === "it"
          ? "https://docs.google.com/document/d/1imTR26-qHTUYk8T2MFq31cCTukGwnD_GYPmUfq53U60"
          : "https://docs.google.com/document/d/1HhBuVtCmOL7yIQT10RbaabCk_7JPObE5",
      title: t("challenge_launch_individual"),
    },
  ])

  useEffect(() => {
    i18next.on("languageChanged", () => {
      setFiles([
        {
          uri:
            i18next.language === "it"
              ? "https://docs.google.com/document/d/1voSdE68eFNa6WHaHzDNE2aQ6Y5mvtzs-RLLOG7MCKMA"
              : "https://docs.google.com/document/d/1tQZXrQN8J9fgrqV3yVPaBgXlir47OeV_",
          title: t("team_launch"),
        },
        {
          uri:
            i18next.language === "it"
              ? "https://docs.google.com/document/d/1U5lhDGMB61roElSCnf1dRWYSXd2inoy9gdjlbta0Zqg"
              : "https://docs.google.com/document/d/1cGOC-pPyyG82TH1GPBynWASsL2iF9SwV",
          title: t("challenge_launch_group"),
        },
        {
          uri:
            i18next.language === "it"
              ? "https://docs.google.com/document/d/1imTR26-qHTUYk8T2MFq31cCTukGwnD_GYPmUfq53U60"
              : "https://docs.google.com/document/d/1HhBuVtCmOL7yIQT10RbaabCk_7JPObE5",
          title: t("challenge_launch_individual"),
        },
      ])
    })

    return () => {
      i18next.off("languageChanged")
    }
  }, [])

  return (
    <PageContainer
      title={t("mail_flow_storytelling")}
      breadcrumbs={[
        { title: t("operational_kit"), path: "/library" },
        { title: t("mail_flow_storytelling") },
      ]}
      withFilters={false}
      withDatePicker={false}
      withExportButton={false}
      paddingTop={24}
    >
      <Title fontSize={14}>{t("mail_flow_description")}</Title>
      <Grid container spacing={3} columns={3} style={{ marginTop: 16 }}>
        {files.map((file, index) => (
          <Grid item xs={3} md={1} key={index}>
            <FadeFromTop delay={0.025 * index}>
              <ResourceCard file={file} />
            </FadeFromTop>
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  )
}

export default MailFlowStorytelling
