import { Stack } from "@mui/material"
import { defaultBoxShadow } from "../../services/config/constants"
import Text from "../global/Text"
import { colors } from "../../services/config/colors"
import Title from "../global/Title"
import Category from "../../models/category"
import {
  formatNumber,
  roundWithDecimalPlaces,
} from "../../services/utils/utils"
import { t } from "i18next"

const ContentCard = ({
  index,
  title,
  image,
  count,
  category,
  type,
}: {
  index: number
  title: string
  image?: string
  count: number
  category: Category
  type: "action" | "episode"
}) => {
  return (
    <Stack
      style={{
        width: "100%",
        height: 220,
        borderRadius: 8,
        boxShadow: defaultBoxShadow,
        overflow: "hidden",
      }}
    >
      <img
        src={image}
        style={{
          width: "100%",
          height: 95,
          objectFit: "cover",
          objectPosition: "center",
          borderRadius: 8,
        }}
        alt=""
      />
      <Text
        fontSize={14}
        lineHeight="17px"
        fontWeight={500}
        paddingLeft={6}
        paddingRight={6}
        style={{
          height: 54,
          maxHeight: 54,
          marginTop: 8,
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {index + 1}. {title}
      </Text>
      <Text
        fontSize={10}
        lineHeight="12px"
        fontWeight={500}
        paddingLeft={6}
        paddingRight={6}
        color={colors.textSecondary}
        style={{
          marginTop: 6,
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {type === "episode" ? t("played") : t("logged")}{" "}
        {count >= 10000
          ? formatNumber(roundWithDecimalPlaces(count / 1000, 1)) + "K"
          : formatNumber(count)}
        <br />
        {t("times")}
      </Text>
      <div
        className="center"
        style={{
          width: "fit-content",
          height: 17,
          borderRadius: 3,
          marginLeft: 6,
          marginTop: 7,
          backgroundColor: category.backTagColor,
          paddingInline: 2,
        }}
      >
        <Title fontSize={12} color={category.foreColor}>
          {category.name}
        </Title>
      </div>
    </Stack>
  )
}

export default ContentCard
