import { Skeleton, Stack } from "@mui/material"
import { CSSProperties, useContext } from "react"
import Text from "../global/Text"
import { colors } from "../../services/config/colors"
import PointsBadge from "../global/PointsBadge"
import { MainContext } from "../../controllers/main"

const ChallengeLeaderboardTop3 = ({ style }: { style?: CSSProperties }) => {
  const { dataLoading, leaderboard } = useContext(MainContext)

  return (
    <Stack
      direction="row"
      style={{
        width: "100%",
        height: 148,
        paddingInline: 16,
        ...style,
      }}
    >
      <Stack direction="row-reverse" style={{ width: "calc(100% / 3 * 2)" }}>
        {/* first position */}
        <Stack
          alignItems="center"
          justifyContent="flex-start"
          style={{ width: "calc(100% / 2)", height: "100%" }}
          aria-label="Place 1 on the leaderboard"
        >
          {dataLoading ? (
            <Skeleton variant="circular" style={{ width: 81, height: 81 }} />
          ) : (
            <div
              style={{
                width: 81,
                height: 81,
                borderRadius: "100%",
                backgroundImage: leaderboard[0]
                  ? `url(${leaderboard[0].user.profileImage}), url(http://cdn.aworld.io/users/${leaderboard[0].user.sub}/profile.jpg), url(https://cdn.aworld.io/users/default/profile.jpg)`
                  : "url(https://cdn.aworld.io/users/default/profile.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: 22,
                  height: 22,
                  borderRadius: "100%",
                  backgroundColor: colors.primary,
                  position: "absolute",
                  top: 4,
                  left: -6,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text fontSize={14} fontWeight={700} color={colors.textWhite}>
                  1
                </Text>
              </div>
            </div>
          )}
          <Text
            fontSize={14}
            lineHeight="19px"
            fontWeight={700}
            style={{
              height: 19,
              marginTop: 2,
              maxWidth: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            shouldLoad
            skeletonWidth={70}
          >
            {leaderboard[0]
              ? leaderboard[0].user.nickname ?? leaderboard[0].user.uid
              : ""}
          </Text>
          {leaderboard[0] ? (
            <PointsBadge
              points={leaderboard[0].userPoints}
              style={{ marginTop: 4.5 }}
            />
          ) : (
            <div
              style={{
                width: "auto",
                height: 22,
                marginTop: 4.5,
              }}
            />
          )}
        </Stack>
        {/* second position */}
        <Stack
          alignItems="center"
          justifyContent="flex-end"
          style={{ width: "calc(100% / 2)", height: "100%" }}
          aria-label="Place 2 on the leaderboard"
        >
          {dataLoading ? (
            <Skeleton variant="circular" style={{ width: 63, height: 63 }} />
          ) : (
            <div
              style={{
                width: 63,
                height: 63,
                borderRadius: "100%",
                backgroundImage: leaderboard[1]
                  ? `url(${leaderboard[1].user.profileImage}), url(http://cdn.aworld.io/users/${leaderboard[1].user.sub}/profile.jpg), url(https://cdn.aworld.io/users/default/profile.jpg)`
                  : "url(https://cdn.aworld.io/users/default/profile.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: "100%",
                  backgroundColor: colors.primary,
                  position: "absolute",
                  top: 4,
                  left: -6,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text fontSize={14} fontWeight={700} color={colors.textWhite}>
                  2
                </Text>
              </div>
            </div>
          )}
          <Text
            fontSize={14}
            lineHeight="19px"
            fontWeight={700}
            style={{
              height: 19,
              marginTop: 2,
              maxWidth: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            shouldLoad
            skeletonWidth={70}
          >
            {leaderboard[1]
              ? leaderboard[1].user.nickname ?? leaderboard[1].user.uid
              : ""}
          </Text>
          {leaderboard[1] ? (
            <PointsBadge
              points={leaderboard[1].userPoints}
              style={{ marginTop: 4.5 }}
            />
          ) : (
            <div
              style={{
                width: "auto",
                height: 22,
                marginTop: 5,
              }}
            />
          )}
        </Stack>
      </Stack>
      {/* third position */}
      <Stack
        alignItems="center"
        justifyContent="flex-end"
        style={{ width: "calc(100% / 3)", height: "100%" }}
        aria-label="Place 3 on the leaderboard"
      >
        {dataLoading ? (
          <Skeleton variant="circular" style={{ width: 63, height: 63 }} />
        ) : (
          <div
            style={{
              width: 63,
              height: 63,
              borderRadius: "100%",
              backgroundImage: leaderboard[2]
                ? `url(${leaderboard[2].user.profileImage}), url(http://cdn.aworld.io/users/${leaderboard[2].user.sub}/profile.jpg), url(https://cdn.aworld.io/users/default/profile.jpg)`
                : "url(https://cdn.aworld.io/users/default/profile.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: "100%",
                backgroundColor: colors.primary,
                position: "absolute",
                top: 4,
                left: -6,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                fontSize={14}
                fontWeight={700}
                color={colors.textWhite}
                ariaHidden
              >
                3
              </Text>
            </div>
          </div>
        )}
        <Text
          fontSize={14}
          lineHeight="19px"
          fontWeight={700}
          style={{
            height: 19,
            marginTop: 2,
            maxWidth: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          shouldLoad
          skeletonWidth={70}
        >
          {leaderboard[2]
            ? leaderboard[2].user.nickname ?? leaderboard[2].user.uid
            : ""}
        </Text>
        {leaderboard[2] ? (
          <PointsBadge
            points={leaderboard[2].userPoints}
            style={{ marginTop: 4.5 }}
          />
        ) : (
          <div
            style={{
              width: "auto",
              height: 22,
              marginTop: 5,
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default ChallengeLeaderboardTop3
