import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import {
  getCurrentUser,
  signIn,
  confirmSignIn,
  signOut,
} from "aws-amplify/auth"
import { logger, Status } from "../services/utils/utils"
import { useNavigate } from "react-router-dom"
import Team from "../models/team"
import { dashboardUserTeamsList } from "../services/graphql/queries"
import { OperationVariables, QueryResult, useLazyQuery } from "@apollo/client"
import { availableLanguages } from "../services/config/constants"
import i18next from "i18next"

interface AuthContextInterface {
  loading: boolean
  isAuth: boolean
  setIsAuth: Dispatch<SetStateAction<boolean>>
  sendCode: (email: string) => Promise<boolean>
  confirmCode: (code: string) => Promise<boolean>
  logOut: () => void
  logOutAlertOpen: boolean
  setLogOutAlertOpen: Dispatch<SetStateAction<boolean>>
  teamsList: Team[]
  team: Team | null
  setTeam: Dispatch<SetStateAction<Team | null>>
  viewNoTeamPage: boolean
  modalMenuOpen: boolean
  setModalMenuOpen: Dispatch<SetStateAction<boolean>>
  getUserTeamsList: () => Promise<boolean>
  isAdmin: boolean
  isSuperAdmin: boolean
}

const AuthContext = createContext<AuthContextInterface>({
  loading: true,
  isAuth: false,
  setIsAuth: () => {},
  sendCode: async () => true,
  confirmCode: async () => true,
  logOut: () => {},
  logOutAlertOpen: false,
  setLogOutAlertOpen: () => {},
  teamsList: [],
  team: null,
  setTeam: () => {},
  viewNoTeamPage: false,
  modalMenuOpen: false,
  setModalMenuOpen: () => {},
  getUserTeamsList: async () => true,
  isAdmin: false,
  isSuperAdmin: false,
})

const AuthController = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()

  // loadings
  const [loading, setLoading] = useState<boolean>(true)

  // states
  const [isAuth, setIsAuth] = useState<boolean>(false)
  const [logOutAlertOpen, setLogOutAlertOpen] = useState<boolean>(false)
  const [teamsList, setTeamsList] = useState<Team[]>([])
  const [team, setTeam] = useState<Team | null>(null)
  const [viewNoTeamPage, setViewNoTeamPage] = useState<boolean>(false)
  const [modalMenuOpen, setModalMenuOpen] = useState<boolean>(false)
  const adminsList = [
    "b42166dc-b6f9-4c59-8fb7-d078d4e36550", // alessio.m@aworld.org
  ] // admins list
  const superAdminsList = [
    "d7e4a138-c0df-4421-9c47-af4b745e785e", // dashboard@aworld.org
  ] // super admins list (can select all dates)
  const [isAdmin, setIsAdmin] = useState<boolean>(false) // if user is admin or not
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false) // if user is super admin or not

  // queries
  const [dashboardUserTeamsListQuery] = useLazyQuery(dashboardUserTeamsList)

  // get user teams list
  const getUserTeamsList = async () => {
    try {
      logger(Status.Api, "QUERY userTeamsList")

      let nextToken = null
      let data: any[] = []
      do {
        const result: QueryResult<any, OperationVariables> =
          await dashboardUserTeamsListQuery({
            variables: {
              input: {
                lang: i18next.language,
                nextToken: nextToken,
              },
            },
          })

        data = [...data, ...result.data.dashboardUserTeamsList.items]
        nextToken = result.data.dashboardUserTeamsList.nextToken
      } while (nextToken !== null)

      // parse data
      const dataToSet = data
        .filter((item: any) => item.team.document)
        .map((item: any) => {
          return {
            id: item.team.id,
            title: item.team.document.title,
            logo: item.team.document.logo,
            isPrimary: item.team.isPrimary,
          }
        })

      logger(Status.Info, "user teams list", dataToSet)

      if (dataToSet.length) {
        setTeamsList(
          dataToSet.sort((a: Team, b: Team) => {
            if (a.title < b.title) {
              return -1
            } else if (a.title > b.title) {
              return 1
            }
            return 0
          })
        )

        if (!team) {
          if (
            localStorage.getItem("team") &&
            dataToSet.some(
              (item: Team) =>
                item.id === JSON.parse(localStorage.getItem("team")!).id
            )
          ) {
            setTeam(JSON.parse(localStorage.getItem("team")!))
          } else {
            setTeam({
              id: dataToSet[0].id,
              title: dataToSet[0].title,
              logo: dataToSet[0].logo,
              isPrimary: dataToSet[0].isPrimary,
            })
          }
        }

        return true
      } else {
        setViewNoTeamPage(true)

        return false
      }
    } catch (e) {
      console.log(e)
      setViewNoTeamPage(true)

      return false
    }
  }

  // check current auth
  const checkAuth = async () => {
    try {
      const currentUser = await getCurrentUser()

      // check if user is admin or super admin
      if (superAdminsList.includes(currentUser.userId)) {
        setIsSuperAdmin(true)
      } else if (adminsList.includes(currentUser.userId)) {
        setIsAdmin(true)
      }

      if (window.location.pathname === "/") {
        navigate("/engagement")
      }

      await getUserTeamsList()

      setIsAuth(true)
      setLoading(false)
    } catch {
      setIsAuth(false)
      localStorage.clear()
      setLoading(false)
    }
  }

  // send code to user via email
  const sendCode = async (email: string) => {
    try {
      await signIn({
        username: email,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP",
        },
      })

      return true
    } catch (e) {
      console.error(e)

      return false
    }
  }

  // check code that user has entered
  const confirmCode = async (code: string) => {
    try {
      const result = await confirmSignIn({ challengeResponse: code })

      if (result.isSignedIn) {
        logger(Status.Info, "right code")

        if (window.location.pathname === "/") {
          navigate("/engagement")
        }

        await getUserTeamsList()

        return true
      } else {
        logger(Status.Warning, "wrong code")

        return false
      }
    } catch (e) {
      logger(Status.Error, "confirmSignIn error")

      return false
    }
  }

  // logout user
  const logOut = async () => {
    try {
      await signOut()

      navigate("/")
      setIsAuth(false)
    } catch (e) {
      console.error(e)
    }
  }

  // initial flow
  useEffect(() => {
    // check user language
    let userLang =
      localStorage.getItem("language") ?? navigator.language.slice(0, 2)
    if (availableLanguages.some((item) => item.id === userLang)) {
      i18next.changeLanguage(userLang)
      localStorage.setItem("language", userLang)
    }

    // check if user is authenticated or not
    checkAuth()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        loading,
        isAuth,
        setIsAuth,
        sendCode,
        confirmCode,
        logOut,
        logOutAlertOpen,
        setLogOutAlertOpen,
        teamsList,
        team,
        setTeam,
        viewNoTeamPage,
        modalMenuOpen,
        setModalMenuOpen,
        getUserTeamsList,
        isAdmin,
        isSuperAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
export { AuthController, AuthContext }
