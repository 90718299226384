import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { CSSProperties, ReactNode, useContext } from "react"
import chevronRightIcon from "../../assets/icons/chevron-right.svg"
import chevronRightSmallIcon from "../../assets/icons/chevron-right-small.svg"
import { MainContext } from "../../controllers/main"
import { defaultTransition } from "../../services/config/constants"

const InfoCard = ({
  children,
  clickable = false,
  size = "normal",
  onClick,
  style,
  withBackground = true,
  withArrow = true,
}: {
  children?: ReactNode
  clickable?: boolean
  size?: "small" | "normal"
  onClick?: () => void
  style?: CSSProperties
  withBackground?: boolean
  withArrow?: boolean
}) => {
  const { dataLoading } = useContext(MainContext)

  return clickable ? (
    <ButtonBase
      disabled={dataLoading}
      style={{
        width: "100%",
        borderRadius: size === "normal" ? 5 : 8,
        ...style,
      }}
      onClick={onClick}
    >
      <Stack
        style={{
          width: "100%",
          padding: size === "normal" ? 16 : 8,
          backgroundColor: withBackground ? colors.background : "transparent",
          border: withBackground ? "1px solid " + colors.disabled : "none",
          borderRadius: size === "normal" ? 5 : 8,
          position: "relative",
        }}
      >
        {children}
        {withArrow && (
          <img
            src={size === "normal" ? chevronRightIcon : chevronRightSmallIcon}
            style={{
              width: 24,
              height: 24,
              position: "absolute",
              right: size === "normal" ? 12 : 8,
              top: 0,
              bottom: 0,
              marginTop: "auto",
              marginBottom: "auto",
              transition: defaultTransition,
              opacity: dataLoading ? 0.4 : 1,
            }}
          />
        )}
      </Stack>
    </ButtonBase>
  ) : (
    <Stack
      style={{
        width: "100%",
        padding: size === "normal" ? 16 : 8,
        backgroundColor: withBackground ? colors.background : "transparent",
        border: withBackground ? "1px solid " + colors.disabled : "none",
        borderRadius: size === "normal" ? 5 : 8,
        position: "relative",
        ...style,
      }}
    >
      {children}
    </Stack>
  )
}

export default InfoCard
