import { t } from "i18next"
import PageContainer from "../../../components/global/PageContainer"

const Users = () => {
  return (
    <PageContainer
      title={t("total_users")}
      breadcrumbs={[
        { title: t("engagement"), path: "/engagement" },
        { title: t("total_users") },
      ]}
      withFilters={false}
      withDatePicker={false}
      paddingTop={24}
    ></PageContainer>
  )
}

export default Users
