import { ButtonBase, Stack } from "@mui/material"
import PageContainer from "../../../components/global/PageContainer"
import Title from "../../../components/global/Title"
import ResourcesGroupCard from "../../../components/library/ResourcesGroupCard"
import mailWhiteIcon from "../../../assets/icons/mail-white.svg"
import infoPrimaryIcon from "../../../assets/icons/info-primary.svg"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import { t } from "i18next"
import { useNavigate } from "react-router-dom"
import AlertInfo from "../../../components/global/AlertInfo"
import { useState } from "react"

const Library = () => {
  const navigate = useNavigate()

  // info alerts
  const [mailAlertOpen, setMailAlertOpen] = useState<boolean>(false)

  return (
    <PageContainer
      title={t("operational_kit")}
      breadcrumbs={[{ title: t("operational_kit") }]}
      withFilters={false}
      withDatePicker={false}
      withExportButton={false}
      paddingTop={24}
    >
      <Title fontSize={14}>{t("operational_kit_description")}</Title>
      {/* mail section */}
      <FadeFromTop style={{ width: "100%", marginTop: 40 }}>
        <Stack gap={3} style={{ width: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            style={{ width: "100%", position: "relative" }}
          >
            <Title>{t("mail")}</Title>
            <ButtonBase
              style={{
                width: 28,
                height: 28,
                borderRadius: "100%",
                position: "absolute",
                right: 0,
              }}
              onClick={() => {
                setMailAlertOpen(true)
              }}
            >
              <img
                src={infoPrimaryIcon}
                style={{ width: "100%", height: "100%" }}
              />
            </ButtonBase>
          </Stack>
          <ResourcesGroupCard
            icon={mailWhiteIcon}
            title={t("mail_flow_motivational")}
            subtitle={t("mail_flow_description")}
            count={1}
            onClick={() => {
              navigate("/library/motivational")
            }}
          />
          <ResourcesGroupCard
            icon={mailWhiteIcon}
            title={t("mail_flow_storytelling")}
            subtitle={t("mail_flow_description")}
            count={3}
            onClick={() => {
              navigate("/library/storytelling")
            }}
          />
        </Stack>
      </FadeFromTop>
      {/* alerts */}
      <AlertInfo
        open={mailAlertOpen}
        setOpen={setMailAlertOpen}
        title={t("mail")}
        description="Description"
      />
    </PageContainer>
  )
}

export default Library
