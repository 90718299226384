import { useContext } from "react"
import PageContainer from "../../../components/global/PageContainer"
import { t } from "i18next"
import { AuthContext } from "../../../controllers/auth"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import { colors } from "../../../services/config/colors"

const User = () => {
  const { team } = useContext(AuthContext)

  return (
    <PageContainer
      title={t("user")}
      breadcrumbs={[{ title: t("user") }]}
      withFilters={false}
      withDatePicker={false}
      withExportButton={false}
    >
      {!team ? null : (
        <FadeFromTop
          style={{
            height: "74vh",
            backgroundColor: colors.backgroundWhite,
            borderRadius: 4,
            overflow: "hidden",
          }}
        >
          <iframe
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
            src={
              "https://lookerstudio.google.com/embed/reporting/eaf9947c-a0e3-4936-b6a7-3b0b64d581dd/page/n0lXE?params=" +
              encodeURI(
                JSON.stringify({
                  "ds1.teamid": team.id,
                  "ds7.teamid": team.id,
                  "ds10.teamid": team.id,
                })
              )
            }
          />
        </FadeFromTop>
      )}
    </PageContainer>
  )
}

export default User
