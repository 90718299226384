import { Stack } from "@mui/material"
import Text from "./Text"
import Title from "./Title"
import { colors } from "../../services/config/colors"
import { CSSProperties } from "react"
import { formatNumber } from "../../services/utils/utils"

const InfoStack = ({
  title,
  value,
  percentage,
  style,
}: {
  title: string
  value?: string | number | null
  percentage?: string
  style?: CSSProperties
}) => {
  return (
    <Stack style={style}>
      <Text fontSize={12} lineHeight="18px" color={colors.textSecondary}>
        {title}
      </Text>
      <Title
        fontSize={22}
        lineHeight="25px"
        style={{
          marginTop: 4,
          marginBottom: 3,
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        shouldLoad
      >
        {typeof value === "number" ? formatNumber(value) : value}
      </Title>
      <Text
        fontSize={12}
        lineHeight="14px"
        fontWeight={500}
        color={colors.success}
        style={{
          marginTop: 3,
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        shouldLoad
      >
        {percentage}
      </Text>
    </Stack>
  )
}

export default InfoStack
