import { useContext } from "react"
import Loading from "./views/public/loading"
import LogIn from "./views/public/logIn"
import Main from "./views/private/main"
import { AuthContext } from "./controllers/auth"
import Center from "./components/global/Center"
import Text from "./components/global/Text"
import Button from "./components/global/Button"
import { MainController } from "./controllers/main"

const App = () => {
  const { loading, isAuth, viewNoTeamPage, logOut } = useContext(AuthContext)

  if (loading) {
    return <Loading />
  }

  if (!isAuth) {
    return <LogIn />
  }

  if (viewNoTeamPage) {
    return (
      <Center>
        <Text>No teams</Text>
        <Button
          onClick={logOut}
          shouldLoad={false}
          style={{ width: 100, marginTop: 20 }}
        >
          Logout
        </Button>
      </Center>
    )
  }

  return (
    <MainController>
      <Main />
    </MainController>
  )
}

export default App
