import { ButtonBase, Popover, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { CSSProperties, useContext, useState } from "react"
import Title from "./Title"
import "../../styles/select.css"
import { defaultTransition } from "../../services/config/constants"
import chevronDownIcon from "../../assets/icons/chevron-down.svg"
import { MainContext } from "../../controllers/main"
import SelectOption from "../../models/selectOption"

const SelectComplex = ({
  options,
  selectedOption,
  onOptionChange,
  popoverWidth = 120,
  style,
  useDataLoading = true,
  loading,
  disabled,
}: {
  options: SelectOption[]
  selectedOption: SelectOption
  onOptionChange: (newOption: SelectOption) => void
  popoverWidth?: number
  style?: CSSProperties
  useDataLoading?: boolean
  loading?: boolean
  disabled?: boolean
}) => {
  const { dataLoading } = useContext(MainContext)

  // popover logic
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <ButtonBase
        disabled={disabled ? true : useDataLoading ? dataLoading : loading}
        style={{
          width: "100%",
          height: 49,
          backgroundColor: colors.backgroundWhite,
          borderRadius: 8,
          border: "1px solid " + colors.stroke,
          paddingInline: 16,
          justifyContent: "flex-start",
          position: "relative",
          transition: defaultTransition,
          opacity: disabled ? 0.4 : 1,
          ...style,
        }}
        onClick={handleClick}
      >
        <Title
          fontSize={14}
          style={{
            transition: defaultTransition,
            opacity: (useDataLoading ? dataLoading : loading) ? 0.4 : 1,
            whiteSpace: "nowrap",
          }}
        >
          {selectedOption.label}
        </Title>
        <img
          src={chevronDownIcon}
          style={{
            width: 16,
            height: 16,
            position: "absolute",
            right: 8,
            transition: defaultTransition,
            opacity: (useDataLoading ? dataLoading : loading) ? 0.4 : 1,
          }}
        />
      </ButtonBase>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{ paper: "popover-paper" }}
      >
        <Stack
          style={{
            width: popoverWidth,
          }}
        >
          {options.map((option) => (
            <ButtonBase
              key={option.id}
              className={selectedOption.id === option.id ? "" : "select-item"}
              style={{
                width: "100%",
                height: 49,
                paddingLeft: 16,
                justifyContent: "flex-start",
                backgroundColor:
                  selectedOption.id === option.id
                    ? colors.backgroundLight
                    : colors.backgroundWhite,
                transition: defaultTransition,
              }}
              onClick={() => {
                onOptionChange(option)
                handleClose()
              }}
              aria-label={option.label}
            >
              <Title fontSize={14}>{option.label}</Title>
            </ButtonBase>
          ))}
        </Stack>
      </Popover>
    </>
  )
}

export default SelectComplex
