import { useContext } from "react"
import PageContainer from "../../../components/global/PageContainer"
import { t } from "i18next"
import { AuthContext } from "../../../controllers/auth"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import { colors } from "../../../services/config/colors"

const Activities = () => {
  const { team } = useContext(AuthContext)

  return (
    <PageContainer
      title={t("activities_and_missions")}
      breadcrumbs={[{ title: t("activities_and_missions") }]}
      withFilters={false}
      withDatePicker={false}
      withExportButton={false}
    >
      {!team ? null : (
        <FadeFromTop
          style={{
            height: "74vh",
            backgroundColor: colors.backgroundWhite,
            borderRadius: 4,
            overflow: "hidden",
          }}
        >
          <iframe
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
            src={
              "https://lookerstudio.google.com/embed/reporting/4d007a29-9130-4eff-a000-c1c1c6c9d2c2/page/1XiFE?params=" +
              encodeURI(
                JSON.stringify({
                  "ds2.teamid": team.id,
                  "ds6.teamid": team.id,
                  "ds10.teamid": team.id,
                  "ds11.teamid": team.id,
                  "ds14.teamid": team.id,
                  "ds16.teamid": team.id,
                  "ds17.teamid": team.id,
                  "ds21.teamid": team.id,
                })
              )
            }
          />
        </FadeFromTop>
      )}
    </PageContainer>
  )
}

export default Activities
