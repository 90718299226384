import FootprintBreakdownByCategoriesDataItem from "../../models/footprintBreakdownByCategoriesDataItem"
import { deepCopy, roundWithDecimalPlaces } from "./utils"

export const parseFootprintByCategoryData = (data: any) => {
  const dataToSet = deepCopy(data)
  dataToSet.forEach((item: FootprintBreakdownByCategoriesDataItem) => {
    item.categoryEmissionAmount = roundWithDecimalPlaces(
      item.categoryEmissionAmount / 1000,
      1
    )
    item.sectorEmissionAmount = roundWithDecimalPlaces(
      item.sectorEmissionAmount / 1000,
      1
    )
    item.sectorEmissionPercentage = roundWithDecimalPlaces(
      item.sectorEmissionPercentage * 100,
      1
    )
  })

  // aggregate home appliances data
  const totalAmount = dataToSet
    .filter(
      (item: FootprintBreakdownByCategoriesDataItem) =>
        item.sector === "electronicAppliance" ||
        item.sector === "domesticAppliances" ||
        item.sector === "otherExclDomestic"
    )
    .reduce(
      (value: number, item: FootprintBreakdownByCategoriesDataItem) =>
        item.sectorEmissionAmount + value,
      0
    )
  const totalPercentage = dataToSet
    .filter(
      (item: FootprintBreakdownByCategoriesDataItem) =>
        item.sector === "electronicAppliance" ||
        item.sector === "domesticAppliances" ||
        item.sector === "otherExclDomestic"
    )
    .reduce(
      (value: number, item: FootprintBreakdownByCategoriesDataItem) =>
        item.sectorEmissionPercentage + value,
      0
    )
  dataToSet.push({
    category: "lifestyle",
    sector: "appliances",
    sectorEmissionAmount: roundWithDecimalPlaces(totalAmount, 1),
    sectorEmissionPercentage: roundWithDecimalPlaces(totalPercentage, 1),
    categoryEmissionAmount: dataToSet.some(
      (item: FootprintBreakdownByCategoriesDataItem) =>
        item.category === "lifestyle"
    )
      ? dataToSet.find(
          (item: FootprintBreakdownByCategoriesDataItem) =>
            item.category === "lifestyle"
        ).categoryEmissionAmount
      : 0,
  })

  // aggregate clothes and shoes data
  if (
    dataToSet.some(
      (item: FootprintBreakdownByCategoriesDataItem) =>
        item.sector === "clothes"
    ) &&
    dataToSet.some(
      (item: FootprintBreakdownByCategoriesDataItem) => item.sector === "shoes"
    )
  ) {
    const clothesTotalAmount =
      dataToSet.find(
        (item: FootprintBreakdownByCategoriesDataItem) =>
          item.sector === "clothes"
      ).sectorEmissionAmount +
      dataToSet.find(
        (item: FootprintBreakdownByCategoriesDataItem) =>
          item.sector === "shoes"
      ).sectorEmissionAmount
    const clothesTotalPercentage =
      dataToSet.find(
        (item: FootprintBreakdownByCategoriesDataItem) =>
          item.sector === "clothes"
      ).sectorEmissionPercentage +
      dataToSet.find(
        (item: FootprintBreakdownByCategoriesDataItem) =>
          item.sector === "shoes"
      ).sectorEmissionPercentage
    dataToSet.find(
      (item: FootprintBreakdownByCategoriesDataItem) =>
        item.sector === "clothes"
    ).sectorEmissionAmount = roundWithDecimalPlaces(clothesTotalAmount, 1)
    dataToSet.find(
      (item: FootprintBreakdownByCategoriesDataItem) =>
        item.sector === "clothes"
    ).sectorEmissionPercentage = roundWithDecimalPlaces(
      clothesTotalPercentage,
      1
    )
    const indexToRemove = dataToSet.findIndex(
      (item: FootprintBreakdownByCategoriesDataItem) => item.sector === "shoes"
    )
    dataToSet.splice(indexToRemove, 1)
  }

  return dataToSet
}
