import { ButtonBase, Popover, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react"
import Title from "./Title"
import "../../styles/select.css"
import { defaultTransition } from "../../services/config/constants"
import chevronDownIcon from "../../assets/icons/chevron-down.svg"
import { t } from "i18next"
import { MainContext } from "../../controllers/main"
import checkWhiteIcon from "../../assets/icons/check-white.svg"
import SelectOption from "../../models/selectOption"
import Text from "./Text"

const SelectMultiple = ({
  options,
  selectedOptions,
  setSelectedOptions,
  allLabel,
  popoverWidth = 120,
  variant = "normal",
  label,
  style,
  shouldGetDataOnClose = false,
}: {
  options: SelectOption[]
  selectedOptions: SelectOption[]
  setSelectedOptions: Dispatch<SetStateAction<SelectOption[]>>
  allLabel?: string
  popoverWidth?: number
  variant?: "compact" | "normal"
  label?: string
  style?: CSSProperties
  shouldGetDataOnClose?: boolean
}) => {
  const { dataLoading, getData } = useContext(MainContext)

  // popover logic
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)

    if (shouldGetDataOnClose) {
      getData()
    }
  }

  const open = Boolean(anchorEl)

  // filtered selected options based on options array
  const filteredSelectedOption = useMemo(() => {
    return selectedOptions.filter((option) =>
      options.some((item) => item.id === option.id)
    )
  }, [options, selectedOptions])

  return (
    <div>
      {/* button */}
      <ButtonBase
        disabled={dataLoading}
        style={{
          width: "100%",
          height: variant === "normal" ? 49 : 35,
          backgroundColor: colors.backgroundWhite,
          borderRadius: 8,
          border: "1px solid " + colors.stroke,
          paddingInline: 16,
          paddingRight: variant === "compact" ? 32 : 16,
          justifyContent: "flex-start",
          position: "relative",
          ...style,
        }}
        onClick={handleClick}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          style={{ width: "100%" }}
        >
          {variant === "compact" ? (
            <Text
              fontSize={12}
              lineHeight="24px"
              fontWeight={500}
              style={{ letterSpacing: "1px" }}
            >
              {label}
            </Text>
          ) : null}
          {filteredSelectedOption.length > 1 && (
            <div
              className="center"
              style={{
                width: "auto",
                height: 24,
                borderRadius: 4,
                backgroundColor: colors.primary,
                paddingInline: 5,
                transition: defaultTransition,
                opacity: dataLoading ? 0.4 : 1,
              }}
            >
              <Title fontSize={14} color={colors.textWhite}>
                {filteredSelectedOption.length}
              </Title>
            </div>
          )}
          <Title
            fontSize={14}
            lineHeight="24px"
            color={
              variant === "compact"
                ? colors.primary
                : !filteredSelectedOption.length
                ? colors.text
                : colors.primary
            }
            style={{
              maxWidth: "95%",
              transition: defaultTransition,
              opacity: dataLoading ? 0.4 : 1,
            }}
            ellipsis
          >
            {!filteredSelectedOption.length
              ? allLabel ?? t("all")
              : filteredSelectedOption.length === 1
              ? filteredSelectedOption[0].label
              : t("selected_plural")}
          </Title>
        </Stack>
        <img
          src={chevronDownIcon}
          style={{
            width: 16,
            height: 16,
            position: "absolute",
            right: 8,
            transition: defaultTransition,
            opacity: dataLoading ? 0.4 : 1,
          }}
        />
      </ButtonBase>
      {/* popover */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{ paper: "popover-paper" }}
      >
        <Stack
          style={{
            width: popoverWidth,
          }}
        >
          {options.map((option) => (
            <ButtonBase
              key={option.id}
              className={
                filteredSelectedOption.some((item) => item.id === option.id)
                  ? ""
                  : "select-item"
              }
              style={{
                width: "100%",
                height: 49,
                paddingLeft: 16,
                justifyContent: "flex-start",
                backgroundColor: filteredSelectedOption.some(
                  (item) => item.id === option.id
                )
                  ? colors.backgroundLight
                  : colors.backgroundWhite,
                transition: defaultTransition,
              }}
              onClick={() => {
                if (selectedOptions.some((item) => item.id === option.id)) {
                  const indexToRemove = selectedOptions.findIndex(
                    (item) => item.id === option.id
                  )
                  selectedOptions.splice(indexToRemove, 1)
                  setSelectedOptions([...selectedOptions])
                } else if (
                  filteredSelectedOption.length + 1 ===
                  options.length
                ) {
                  const newSelectedOptions = selectedOptions.filter(
                    (option) => !options.some((item) => item.id === option.id)
                  )
                  setSelectedOptions(newSelectedOptions)
                } else {
                  selectedOptions.push(option)
                  setSelectedOptions([...selectedOptions])
                }
              }}
              aria-label={option.label}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                style={{ width: "100%" }}
              >
                <div
                  className="center"
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 4,
                    border: filteredSelectedOption.some(
                      (item) => item.id === option.id
                    )
                      ? "1px solid " + colors.primary
                      : "1px solid " + colors.stroke,
                    transition: defaultTransition,
                    backgroundColor: filteredSelectedOption.some(
                      (item) => item.id === option.id
                    )
                      ? colors.primary
                      : colors.backgroundWhite,
                  }}
                >
                  <img src={checkWhiteIcon} alt="" />
                </div>
                <Title
                  fontSize={14}
                  style={{
                    maxWidth: "70%",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {option.label}
                </Title>
              </Stack>
            </ButtonBase>
          ))}
          {options.length ? (
            <div
              style={{
                width: "100%",
                height: 1,
                backgroundColor: colors.stroke,
              }}
            />
          ) : null}
          {/* all button */}
          <ButtonBase
            className={!filteredSelectedOption.length ? "" : "select-item"}
            style={{
              width: "100%",
              height: 49,
              paddingLeft: 16,
              justifyContent: "flex-start",
              backgroundColor: !filteredSelectedOption.length
                ? colors.backgroundLight
                : colors.backgroundWhite,
              transition: defaultTransition,
            }}
            onClick={() => {
              const newSelectedOptions = selectedOptions.filter(
                (option) => !options.some((item) => item.id === option.id)
              )
              setSelectedOptions(newSelectedOptions)
            }}
            aria-label={allLabel ?? t("all")}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <div
                className="center"
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 4,
                  border: !filteredSelectedOption.length
                    ? "1px solid " + colors.primary
                    : "1px solid " + colors.stroke,
                  transition: defaultTransition,
                  backgroundColor: !filteredSelectedOption.length
                    ? colors.primary
                    : colors.backgroundWhite,
                }}
              >
                <img src={checkWhiteIcon} alt="" />
              </div>
              <Title fontSize={14} color={colors.primary}>
                {allLabel ?? t("all")}
              </Title>
            </Stack>
          </ButtonBase>
        </Stack>
      </Popover>
    </div>
  )
}

export default SelectMultiple
