import ReactDOM from "react-dom/client"
import "./styles/index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { Amplify } from "aws-amplify"
import { BrowserRouter } from "react-router-dom"
import { Shadows, ThemeProvider, createTheme } from "@mui/material"
import { colors } from "./services/config/colors"
import { MotionConfig } from "framer-motion"
import "./services/i18n/i18n"
import { AuthController } from "./controllers/auth"
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  concat,
} from "@apollo/client"
import { setContext } from "@apollo/link-context"
import { fetchAuthSession } from "aws-amplify/auth"

// configure amplify
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID!,
      userPoolClientId: process.env.REACT_APP_USER_WEB_CLIENT_ID!,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_DOMAIN!,
          scopes: [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile",
          ],
          responseType: "code",
          redirectSignIn: [
            window.location.hostname === "localhost"
              ? "http://localhost:3000/"
              : process.env.REACT_APP_REDIRECT_SIGN_IN!,
          ],
          redirectSignOut: [
            window.location.hostname === "localhost"
              ? "http://localhost:3000/"
              : process.env.REACT_APP_REDIRECT_SIGN_IN!,
          ],
        },
      },
    },
  },
})

// apollo client
const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT })

const authLink = setContext(async (_, { headers }) => {
  const session = await fetchAuthSession()
  const token = session.tokens?.accessToken.toString()

  return {
    headers: {
      ...headers,
      authorization: token || null,
    },
  }
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authLink, httpLink),
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <BrowserRouter>
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: "light",
          primary: {
            main: colors.primary,
          },
          secondary: {
            main: colors.secondary,
          },
        },
        shadows: Array(25).fill("none") as Shadows,
        breakpoints: {
          values: {
            xs: 0,
            sm: 500,
            md: 700,
            lg: 1024,
            xl: 1280,
          },
        },
      })}
    >
      <ApolloProvider client={client}>
        <AuthController>
          <MotionConfig reducedMotion="user">
            <div
              style={{
                width: "100%",
                height: "100vh",
                minHeight: "100vh",
              }}
            >
              <App />
            </div>
          </MotionConfig>
        </AuthController>
      </ApolloProvider>
    </ThemeProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
