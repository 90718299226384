import i18next, { t } from "i18next"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import PageContainer from "../../../components/global/PageContainer"
import Table from "../../../components/global/Table"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { colors } from "../../../services/config/colors"
import TableRow from "../../../components/global/TableRow"
import { Stack, TableCell } from "@mui/material"
import Text from "../../../components/global/Text"
import { MainContext } from "../../../controllers/main"
import { formatNumber } from "../../../services/utils/utils"
import { AuthContext } from "../../../controllers/auth"
import { useParams } from "react-router-dom"
import ChallengeLeaderboardItem from "../../../models/challengeLeaderboardItem"

const PastChallengeLeaderboard = () => {
  const params = useParams()
  const { team } = useContext(AuthContext)
  const { countriesList, getPastChallengeLeaderboard } = useContext(MainContext)

  // get leaderboard items
  const [loading, setLoading] = useState<boolean>(true)
  const [leaderboard, setLeaderboard] = useState<ChallengeLeaderboardItem[]>([])
  const [leaderboardNextToken, setLeaderboardNextToken] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      const result = await getPastChallengeLeaderboard(
        team!.id,
        params.challengeId!
      )

      setLeaderboard(result.items)
      setLeaderboardNextToken(result.nextToken)
      setLoading(false)
    }

    fetchData()
  }, [i18next.language])

  // page container onScroll event to fetch new items on scroll
  const fetchingMoreItems = useRef<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)

  const onScroll = async () => {
    const mainContainer = document.getElementById("page-container")!

    if (
      mainContainer.scrollTop + 50 >=
        mainContainer.scrollHeight - window.innerHeight &&
      !fetchingMoreItems.current &&
      leaderboardNextToken &&
      !loading
    ) {
      fetchingMoreItems.current = true
      setLoadingMore(true)

      const result = await getPastChallengeLeaderboard(
        team!.id,
        params.challengeId!,
        leaderboardNextToken
      )

      setLeaderboard((current) => [...current, ...result.items])
      setLeaderboardNextToken(result.nextToken)

      fetchingMoreItems.current = false
      setLoadingMore(false)
    }
  }

  // table heading items
  const headingItems = useMemo(() => {
    return [
      loading ? (
        t("users")
      ) : (
        <>
          {t("users")}{" "}
          <span style={{ color: colors.textSecondary }}>
            ({leaderboard.length})
          </span>
        </>
      ),
      t("location"),
      t("total_points"),
    ]
  }, [loading, leaderboard])

  return (
    <PageContainer
      title={t("leaderboard")}
      breadcrumbs={[
        { title: t("engagement"), path: "/engagement" },
        { title: t("past_challenges"), path: "/engagement/challenges" },
        { title: t("leaderboard") },
      ]}
      variant="compact"
      withFilters={false}
      withDatePicker={false}
      onScroll={onScroll}
      csvData={[
        [t("position"), t("nickname"), t("location"), t("total_points")],
        ...leaderboard.map((item, index) => [
          (index + 1).toString(),
          item.user.nickname ?? item.user.uid,
          countriesList.some((country) => country.id === item.user.country)
            ? countriesList.find((country) => country.id === item.user.country)!
                .name
            : "",
          formatNumber(item.userPoints),
        ]),
      ]}
      csvFileName="leaderboard"
    >
      <FadeFromTop>
        <Table
          headingItems={headingItems}
          loading={loading}
          loadingMore={loadingMore}
        >
          {leaderboard.map((item, index) => (
            <TableRow index={index} key={index}>
              <TableCell>
                <Stack direction="row" alignItems="center" style={{ gap: 20 }}>
                  <Text fontSize={14} fontWeight={500}>
                    {index + 1}°
                  </Text>
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: "100%",
                      backgroundImage: `url(${item.user.profileImage}), url(http://cdn.aworld.io/users/${item.user.sub}/profile.jpg), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <Text fontSize={14} fontWeight={500}>
                    {item.user.nickname ?? item.user.uid}
                  </Text>
                </Stack>
              </TableCell>
              <TableCell>
                <Text fontSize={14} fontWeight={500}>
                  {
                    countriesList.find(
                      (country) => country.id === item.user.country
                    )?.name
                  }
                </Text>
              </TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Text fontSize={14} fontWeight={500}>
                    {formatNumber(item.userPoints)}
                  </Text>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </FadeFromTop>
    </PageContainer>
  )
}

export default PastChallengeLeaderboard
