import { ButtonBase, LinearProgress, Stack } from "@mui/material"
import { CSSProperties, ReactNode } from "react"
import { colors } from "../../services/config/colors"
import { defaultTransition } from "../../services/config/constants"
import googleLogo from "../../assets/icons/google.svg"
import facebookLogo from "../../assets/icons/facebook.svg"
import appleLogo from "../../assets/icons/apple.svg"

const SocialButton = ({
  children,
  primary,
  style,
  onClick,
  disabled = false,
  loading = false,
  type,
}: {
  children?: ReactNode
  primary?: boolean
  style?: CSSProperties
  onClick?:
    | (() => void)
    | ((event: React.MouseEvent<HTMLButtonElement>) => void)
  disabled?: boolean
  loading?: boolean
  type: "google" | "facebook" | "apple"
}) => {
  return (
    <ButtonBase
      disabled={loading || disabled}
      aria-disabled={loading || disabled ? "true" : "false"}
      style={{
        width: "auto",
        height: 56,
        minHeight: 56,
        backgroundColor: primary ? colors.primary : colors.backgroundWhite,
        borderRadius: 16,
        fontSize: 16,
        fontWeight: 700,
        color: colors.text,
        opacity: loading || disabled ? 0.4 : 1,
        transition: defaultTransition,
        overflow: "hidden",
        border: "1.5px solid " + colors.primary,
        ...style,
      }}
      onClick={onClick}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        {type === "google" ? (
          <img src={googleLogo} style={{ height: 18 }} alt="" />
        ) : type === "facebook" ? (
          <img src={facebookLogo} style={{ height: 18 }} alt="" />
        ) : type === "apple" ? (
          <img src={appleLogo} style={{ height: 18 }} alt="" />
        ) : null}
        {children}
      </Stack>
      {loading && (
        <LinearProgress
          style={{ width: "100%", position: "absolute", bottom: 0 }}
        />
      )}
    </ButtonBase>
  )
}

export default SocialButton
