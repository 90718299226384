import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { CSSProperties, ReactNode, useContext } from "react"
import { MainContext } from "../../controllers/main"
import { defaultTransition } from "../../services/config/constants"

const InfoCardSelectable = ({
  selected,
  children,
  size = "normal",
  onClick,
  style,
  useDataLoading = true,
  loading,
}: {
  selected: boolean
  children?: ReactNode
  size?: "small" | "normal"
  onClick?: () => void
  style?: CSSProperties
  useDataLoading?: boolean
  loading?: boolean
}) => {
  const { dataLoading } = useContext(MainContext)

  return (
    <ButtonBase
      disabled={useDataLoading ? dataLoading : loading}
      disableRipple
      style={{
        width: "100%",
        borderRadius: size === "normal" ? 5 : 8,
        ...style,
      }}
      onClick={onClick}
    >
      <Stack
        style={{
          width: "100%",
          padding: size === "normal" ? 16 : 8,
          backgroundColor: colors.background,
          border: selected
            ? "2px solid " + colors.disabled
            : "2px solid rgba(0, 0, 0, 0)",
          borderRadius: size === "normal" ? 5 : 8,
          position: "relative",
          transition: defaultTransition,
        }}
      >
        {children}
      </Stack>
    </ButtonBase>
  )
}

export default InfoCardSelectable
