import { Stack } from "@mui/material"
import { useContext, useMemo } from "react"
import { defaultBoxShadow } from "../../services/config/constants"
import Text from "../global/Text"
import { colors } from "../../services/config/colors"
import { MainContext } from "../../controllers/main"
import { t } from "i18next"
import { formatNumber } from "../../services/utils/utils"

const SdgCard = ({ code, count }: { code: string; count: number }) => {
  const { sdgs } = useContext(MainContext)

  // current sdg from list
  const currentSdg = useMemo(() => {
    return sdgs.find((item) => item.code === code)!
  }, [])

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1.5}
      style={{
        width: "100%",
        height: "auto",
        paddingInline: 11,
        paddingTop: 8,
        paddingBottom: 8,
        boxShadow: defaultBoxShadow,
        borderRadius: 8,
      }}
    >
      <img
        src={currentSdg.image}
        style={{
          width: 46,
          minWidth: 46,
          height: 46,
          borderRadius: 4,
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
      <Stack gap={0.75} style={{ width: "100%" }}>
        <Text
          fontSize={16}
          lineHeight="20px"
          fontWeight={500}
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          SDG {parseInt(currentSdg.code)} - {currentSdg.name}
        </Text>
        <Text
          fontSize={14}
          lineHeight="14px"
          fontWeight={500}
          color={colors.textSecondary}
        >
          {formatNumber(count)} {t("times")}
        </Text>
      </Stack>
    </Stack>
  )
}

export default SdgCard
