import { ButtonBase, Fade, Modal } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import closeIcon from "../../assets/icons/close.png"
import Title from "./Title"
import { colors } from "../../services/config/colors"
import { defaultBoxShadow } from "../../services/config/constants"
import { t } from "i18next"

const AlertInfo = ({
  open,
  setOpen,
  title,
  description,
  image,
  descriptionAlign = "center",
  descriptionFontSize = 18,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  title: string
  description: string
  image?: string
  descriptionAlign?: "left" | "center" | "right"
  descriptionFontSize?: number
}) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      aria-labelledby={title}
      aria-describedby={description}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <div
          style={{
            backgroundColor: colors.backgroundWhite,
            width: "calc(100% - 44px)",
            maxWidth: 375,
            height: "auto",
            maxHeight: "90vh",
            overflowY: "scroll",
            borderRadius: 16,
            paddingInline: 24,
            paddingBottom: 38,
            paddingTop: image ? 30 : 38,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <ButtonBase
            style={{
              position: "absolute",
              top: 16,
              right: 16,
              width: 36,
              height: 36,
              borderRadius: 18,
              backgroundColor: colors.backgroundWhite,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: defaultBoxShadow,
            }}
            onClick={() => {
              setOpen(false)
            }}
            aria-label={t("close")}
          >
            <img src={closeIcon} style={{ width: 10, height: 10 }} alt="" />
          </ButtonBase>
          {image ? (
            <img src={image} style={{ width: 115, height: 115 }} alt="" />
          ) : null}
          <Title
            id={title}
            fontWeight={600}
            lineHeight="32px"
            style={{ marginTop: image ? 20 : 0 }}
            textAlign="center"
            paddingLeft={28}
            paddingRight={28}
          >
            {title}
          </Title>
          <div
            id={description}
            dangerouslySetInnerHTML={{ __html: description }}
            style={{
              fontSize: descriptionFontSize,
              fontWeight: 400,
              lineHeight: "25px",
              color: colors.text,
              marginTop: 20,
              textAlign: descriptionAlign,
              padding: 0,
            }}
          />
        </div>
      </Fade>
    </Modal>
  )
}

export default AlertInfo
