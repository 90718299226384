import { Stack } from "@mui/material"
import Text from "./Text"
import { colors } from "../../services/config/colors"

const BarChartLegendItem = ({
  label,
  color,
}: {
  label: string
  color: string
}) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <div
        style={{
          width: 12,
          height: 12,
          borderRadius: "100%",
          backgroundColor: color,
        }}
      />
      <Text fontSize={14} fontWeight={500} color={colors.textSecondary}>
        {label}
      </Text>
    </Stack>
  )
}

export default BarChartLegendItem
