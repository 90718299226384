import { ButtonBase, LinearProgress } from "@mui/material"
import { CSSProperties, ReactNode, useContext } from "react"
import { colors } from "../../services/config/colors"
import {
  defaultBoxShadow,
  defaultTransition,
} from "../../services/config/constants"
import { MainContext } from "../../controllers/main"

const Button = ({
  children,
  primary,
  size = "small",
  style,
  onClick,
  disabled = false,
  loading = false,
  shouldLoad = true,
  withShadow = true,
  withBorder = false,
  submit = false,
  ariaLabel,
}: {
  children?: ReactNode
  primary?: boolean
  size?: "small" | "normal"
  style?: CSSProperties
  onClick?:
    | (() => void)
    | ((event: React.MouseEvent<HTMLButtonElement>) => void)
  disabled?: boolean
  loading?: boolean
  shouldLoad?: boolean
  withShadow?: boolean
  withBorder?: boolean
  submit?: boolean
  ariaLabel?: string
}) => {
  const { dataLoading } = useContext(MainContext)

  return (
    <ButtonBase
      type={submit ? "submit" : "button"}
      disabled={loading || (shouldLoad && dataLoading) || disabled}
      aria-disabled={
        loading || (shouldLoad && dataLoading) || disabled ? "true" : "false"
      }
      style={{
        width: "auto",
        height: size === "small" ? 40 : 56,
        minHeight: size === "small" ? 40 : 56,
        backgroundColor: primary ? colors.primary : colors.backgroundWhite,
        borderRadius: size === "small" ? 10 : 16,
        fontSize: size === "small" ? 12 : 16,
        fontWeight: size === "small" ? 900 : 700,
        color: primary ? colors.textWhite : colors.primary,
        boxShadow: withShadow ? defaultBoxShadow : "none",
        border: withBorder ? "1.5px solid " + colors.primary : "none",
        opacity: loading
          ? 0.4
          : (shouldLoad && dataLoading) || disabled
          ? 0.4
          : 1,
        transition: defaultTransition,
        overflow: "hidden",
        ...style,
      }}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {children}
      {loading && (
        <LinearProgress
          style={{ width: "100%", position: "absolute", bottom: 0 }}
        />
      )}
    </ButtonBase>
  )
}

export default Button
