import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import Title from "../global/Title"
import Text from "../global/Text"
import chevronRightIcon from "../../assets/icons/chevron-right.svg"

const ResourcesGroupCard = ({
  icon,
  title,
  subtitle,
  count,
  onClick,
}: {
  icon: string
  title: string
  subtitle: string
  count: number
  onClick: () => void
}) => {
  return (
    <ButtonBase
      style={{ width: "100%", minHeight: 75, borderRadius: 16 }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        style={{
          width: "100%",
          height: "100%",
          paddingInline: 18,
          paddingTop: 16,
          paddingBottom: 16,
          position: "relative",
          borderRadius: 16,
          backgroundColor: colors.backgroundWhite,
        }}
        gap={2}
      >
        <div
          className="center"
          style={{
            width: 40,
            minWidth: 40,
            height: 40,
            borderRadius: 10,
            backgroundColor: colors.primary,
          }}
        >
          <img src={icon} />
        </div>
        <Stack gap={0.5} style={{ width: "100%" }}>
          <Title fontSize={16} lineHeight="19px">
            {title}
          </Title>
          <Text
            fontSize={12}
            lineHeight="14px"
            fontWeight={500}
            color={colors.textSecondary}
            style={{ maxWidth: "calc(100% - 90px)" }}
          >
            {subtitle}
          </Text>
        </Stack>
        <div
          className="center"
          style={{
            width: 28,
            height: 28,
            borderRadius: "100%",
            backgroundColor: colors.primary,
            position: "absolute",
            right: 62,
          }}
        >
          <Title fontSize={14} color={colors.textWhite}>
            {count}
          </Title>
        </div>
        <img
          src={chevronRightIcon}
          style={{ height: 16, position: "absolute", right: 25 }}
        />
      </Stack>
    </ButtonBase>
  )
}

export default ResourcesGroupCard
