import { CSSProperties, ReactNode } from "react"

const Center = ({
  children,
  style,
}: {
  children?: ReactNode
  style?: CSSProperties
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default Center
