import { ButtonBase, Popover, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { useContext, useState } from "react"
import "../../styles/select.css"
import { defaultTransition } from "../../services/config/constants"
import chevronDownPrimaryIcon from "../../assets/icons/chevron-down-primary.svg"
import { MainContext } from "../../controllers/main"
import Title from "../global/Title"
import { t } from "i18next"
import Team from "../../models/team"
import { client } from "../.."

const TeamSelect = ({
  options,
  selectedOption,
  onChange,
  popoverWidth = 250,
}: {
  options: Team[]
  selectedOption: Team | null
  onChange: (newTeam: Team) => void
  popoverWidth?: number
}) => {
  const { dataLoading } = useContext(MainContext)

  // popover logic
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <ButtonBase
        disableRipple
        style={{
          justifyContent: "flex-start",
          transition: defaultTransition,
          opacity: dataLoading ? 0.4 : 1,
        }}
        onClick={handleClick}
        disabled={dataLoading}
        aria-disabled={dataLoading ? "true" : "false"}
      >
        <Stack direction="row" gap={1} style={{ height: 20 }}>
          <Title fontSize={14} color={colors.primary}>
            {t("change_team")}
          </Title>
          <img
            src={chevronDownPrimaryIcon}
            style={{
              width: 12,
              transform: open ? "rotate(180deg)" : "none",
              transition: "150ms",
            }}
            alt=""
          />
        </Stack>
      </ButtonBase>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{ paper: "popover-paper" }}
      >
        <Stack
          style={{
            width: popoverWidth,
          }}
        >
          {options.map((option) => (
            <ButtonBase
              key={option.id}
              className={
                selectedOption && selectedOption.id === option.id
                  ? ""
                  : "select-item"
              }
              style={{
                width: "100%",
                height: 49,
                paddingLeft: 16,
                justifyContent: "flex-start",
                backgroundColor:
                  selectedOption && selectedOption.id === option.id
                    ? colors.backgroundLight
                    : colors.backgroundWhite,
                transition: defaultTransition,
              }}
              onClick={async () => {
                // empty apollo client cache
                await client.clearStore()

                // call onChange function prop
                onChange(option)

                // save selected team to local storage
                localStorage.setItem("team", JSON.stringify(option))

                handleClose()
              }}
              aria-label={option.title}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                <img
                  src={option.logo}
                  style={{
                    width: 22,
                    minWidth: 22,
                    height: 22,
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: "100%",
                  }}
                  alt=""
                />
                <Title
                  fontSize={14}
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {option.title}
                </Title>
              </Stack>
            </ButtonBase>
          ))}
        </Stack>
      </Popover>
    </>
  )
}

export default TeamSelect
