import { Stack } from "@mui/material"
import { FilterType } from "../../services/config/enum"
import Title from "./Title"
import SelectMultiple from "./SelectMultiple"
import { Dispatch, SetStateAction, useContext, useMemo } from "react"
import { t } from "i18next"
import SelectOption from "../../models/selectOption"
import { MainContext } from "../../controllers/main"

const Filter = ({
  type,
  selectedOptions,
  setSelectedOptions,
  variant = "normal",
}: {
  type: FilterType
  selectedOptions: SelectOption[]
  setSelectedOptions: Dispatch<SetStateAction<SelectOption[]>>
  variant?: "compact" | "normal"
}) => {
  const { groupsList, countriesList } = useContext(MainContext)

  // options array based on type
  const options = useMemo(() => {
    switch (type) {
      case FilterType.age:
        return [
          { id: "13-19", label: "13-19" },
          { id: "20-27", label: "20-27" },
          { id: "27-34", label: "27-34" },
          { id: "34-41", label: "34-41" },
        ]
      case FilterType.gender:
        return [
          { id: "male", label: t("male") },
          { id: "female", label: t("female") },
          { id: "unknown", label: t("unknown") },
        ]
      case FilterType.group:
        return groupsList.map((group) => {
          return {
            id: group.id,
            label: group.name,
            type: "group",
          }
        })
      case FilterType.location:
        return countriesList.map((country) => {
          return {
            id: country.id,
            label: country.name,
            type: "location",
          }
        })
    }
  }, [type, groupsList, countriesList])

  return (
    <Stack
      gap={1}
      style={{
        width: variant === "compact" ? "auto" : 172,
        height: variant === "normal" ? 74 : 35,
      }}
    >
      {variant === "normal" && (
        <Title fontSize={14} lineHeight="17px">
          {t(type)}
        </Title>
      )}
      <SelectMultiple
        options={options}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        popoverWidth={172}
        label={t(type)}
        variant={variant}
        shouldGetDataOnClose
      />
    </Stack>
  )
}

export default Filter
