import chalk from "chalk"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import { forwardRef, ReactElement, Ref } from "react"
import { loggerEnabled } from "../config/constants"
import i18next, { t } from "i18next"

// status enum for logger
export enum Status {
  Info,
  Warning,
  Error,
  Api,
  Important,
}

// to log with 4 different status
export const logger = (status: Status, message: string, value: any = null) => {
  if (loggerEnabled) {
    if (status === Status.Info) {
      if (value) {
        console.log(chalk.bgWhite.black(message), value)
      } else {
        console.log(chalk.bgWhite.black(message))
      }
    } else if (status === Status.Warning) {
      if (value) {
        console.log(chalk.bgYellow.black(message), value)
      } else {
        console.log(chalk.bgYellow.black(message))
      }
    } else if (status === Status.Error) {
      if (value) {
        console.log(chalk.bgRed.black(message), value)
      } else {
        console.log(chalk.bgRed.black(message))
      }
    } else if (status === Status.Api) {
      if (value) {
        console.log(chalk.bgBlueBright.black(message), value)
      } else {
        console.log(chalk.bgBlueBright.black(message))
      }
    } else if (status === Status.Important) {
      if (value) {
        console.log(chalk.bgWhite.black.bold(message), value)
      } else {
        console.log(chalk.bgWhite.black.bold(message))
      }
    }
  }
}

// parse a string date into a readable one
export const parseStringDate = (stringDate: string, withHours = true) => {
  let months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ]

  let date = new Date(stringDate)

  if (withHours) {
    return `${date.getDate()} ${
      months[date.getMonth()]
    } ${date.getFullYear()} ${
      date.getHours().toString().length === 1
        ? "0" + date.getHours()
        : date.getHours()
    }:${
      date.getMinutes().toString().length === 1
        ? "0" + date.getMinutes()
        : date.getMinutes()
    }`
  } else {
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
  }
}

// compose controllers
interface Props {
  components: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>
  children: React.ReactNode
}

export const Compose = (props: Props) => {
  const { components = [], children } = props

  return (
    <>
      {components.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>
      }, children)}
    </>
  )
}

// transition for dialogs
export const DialogTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

// deep copy an object or an array
export const deepCopy = (input: any) => {
  return JSON.parse(JSON.stringify(input))
}

// capitalize first character of a string
export const capitalizeFirstCharacter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

// make lowercase first character of a string
export const lowercaseFirstCharacter = (text: string) => {
  return text.charAt(0).toLowerCase() + text.slice(1)
}

// preview sizes
export const screenWidth = 375
export const screenHeight = 812
export const columnWidth = 375 / 15
export const rowHeight = screenHeight / 43

// calculate the time elapsed since a date
export const calculateTimeElapsed = (stringDate: string) => {
  let today = new Date()
  let date = new Date(stringDate)

  let milliseconds = today.getTime() - date.getTime()
  if (milliseconds < 1000) {
    return "now"
  }
  let seconds = Math.round(milliseconds / 1000)
  if (seconds < 120) {
    return "1 minute ago"
  }
  let minutes = Math.round(seconds / 60)
  if (minutes < 60) {
    return minutes + " minutes ago"
  }
  if (minutes >= 60 && minutes < 120) {
    return "1 hour ago"
  }
  let hours = Math.round(minutes / 60)
  if (hours < 24) {
    return hours + " hours ago"
  }
  if (hours >= 24 && hours < 48) {
    return "1 day ago"
  }
  let days = Math.round(hours / 24)
  if (days < 7) {
    return days + " days ago"
  }
  if (days >= 7 && days < 14) {
    return "1 week ago"
  }
  let weeks = Math.round(days / 7)
  if (weeks < 4) {
    return weeks + " weeks ago"
  }
  if (weeks >= 4 && weeks < 8) {
    return "1 month ago"
  }
  let months = Math.round(weeks / 4)
  if (months < 12) {
    return months + " months ago"
  }
  if (months >= 12 && months < 24) {
    return "1 year ago"
  }
  let years = Math.round(months / 12)
  return years + " years ago"
}

// generate random alphanumeric string of the given length
export const generateRandomString = (length: number) => {
  var result = ""
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

// check if a YouTube url is valid or not
export const testYtURL = (url: string) => {
  return /www.youtube.com\/[A-z0-9]+/.test(url)
}

// date to string format for queries
export const dateToStringForQueries = (date: Date) => {
  let year = date.getFullYear().toString()
  let month = (date.getMonth() + 1).toString()
  let day = date.getDate().toString()

  if (month.toString().length === 1) {
    month = "0" + month
  }
  if (day.toString().length === 1) {
    day = "0" + day
  }

  return year + "-" + month + "-" + day
}

// copy text to clipboard
export const copyTextToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
}

// Enum as Array https://stackoverflow.com/questions/41308123/map-typescript-enum
export const enumAsArray = (enumToMap: any) => {
  return Object.keys(enumToMap) as Array<keyof typeof enumToMap>
}

// check string date with format yyyy-mm-dd
export const isValidDate = (dateString: string) => {
  var regEx = /^\d{4}-\d{2}-\d{2}$/
  if (!dateString.match(regEx)) return false // Invalid format
  var d = new Date(dateString)
  var dNum = d.getTime()
  if (!dNum && dNum !== 0) return false // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString
}

// check url
export const isValidURL = (stringToTest: string) => {
  const pattern = new RegExp(
    "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"
  )
  return pattern.test(stringToTest)
}

// validate privacy version for channels
export const isPrivacyVersionValid = (privacyVersion: string) => {
  // regex for one number only
  const regex = /^\d+$/

  if (
    privacyVersion.length === 5 &&
    regex.test(privacyVersion.charAt(0)) &&
    regex.test(privacyVersion.charAt(2)) &&
    regex.test(privacyVersion.charAt(4)) &&
    privacyVersion.charAt(1) === "." &&
    privacyVersion.charAt(3) === "."
  ) {
    return true
  } else {
    return false
  }
}

// strip html from a string
export const stripHtml = (html: string) => {
  let tmp = document.createElement("DIV")
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ""
}

// add days to date
export const addDays = (date: Date, days: number) => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

// blob to base64 string
export const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

// add hours to a date
export const addHours = (date: Date, hours: number) => {
  date.setHours(date.getHours() + hours)

  return date
}

// return a random number included in a provided interval
export const randomNumberFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

// round number to 1 decimal places if necessary
export const roundWithDecimalPlaces = (num: number, decimalPlaces: number) => {
  const value = Math.pow(10, decimalPlaces)

  const rounded = Number(
    (Math.round((num + Number.EPSILON) * value) / value).toFixed(decimalPlaces)
  )
  return rounded % 1 === 0 ? Math.trunc(rounded) : rounded
}

// subtract months from a given date
export const subtractMonths = (date: Date, months: number) => {
  const dateToEdit = new Date(date)
  dateToEdit.setMonth(dateToEdit.getMonth() - months)

  return dateToEdit
}

// get last day in number of a specified month
export const getLastDayOfMonth = (year: number, month: number): number => {
  // create a date object for the first day of the next month, then subtract one day
  const date = new Date(year, month + 1, 0)
  // return the day of the month
  return date.getDate()
}

// get monday of a specific week
export const getMonday = (d: Date): Date => {
  const date = new Date(d)
  const day = date.getDay()
  const diff = (day === 0 ? -6 : 1) - day // adjust when day is sunday
  date.setDate(date.getDate() + diff)
  return date
}

// get sunday of a specific week
export const getSunday = (d: Date): Date => {
  const date = new Date(d)
  const day = date.getDay()
  const diff = day === 0 ? 0 : 7 - day // if today is sunday, diff is 0
  date.setDate(date.getDate() + diff)
  return date
}

// calculate the number of days between two dates
export const calculateDaysBetweenDates = (date1: Date, date2: Date): number => {
  // calculate the difference in time
  const timeDifference = Math.abs(date2.getTime() - date1.getTime())

  // convert the time difference from milliseconds to days
  const dayDifference = timeDifference / (1000 * 3600 * 24)

  return dayDifference
}

// transform number to hours and minutes (e.g. 0.75 = 45 min)
export const convertNumberToHoursMinutes = (decimalHours: number): string => {
  const hours = Math.floor(decimalHours)
  const minutes = Math.round((decimalHours - hours) * 60)

  if (hours) {
    return `${hours}${t("hours_short")} ${minutes}${t("minutes_short")}`
  } else {
    return `${minutes}${t("minutes_short")}`
  }
}

// format number with Intl
export const formatNumber = (n?: number | null): string => {
  if (n) {
    return new Intl.NumberFormat(i18next.language).format(n)
  }

  return "0"
}
