import i18next, { t } from "i18next"
import PageContainer from "../../../components/global/PageContainer"
import Title from "../../../components/global/Title"
import ResourceCard from "../../../components/library/ResourceCard"
import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import File from "../../../models/file"
import FadeFromTop from "../../../components/animations/FadeFromTop"

const MailFlowMotivational = () => {
  // array of files
  const [files, setFiles] = useState<File[]>([
    {
      uri:
        i18next.language === "it"
          ? "https://docs.google.com/document/d/1Pl2NxVvkoOkvGs05AMeRs3qBxHuaXiqDUnAK8pGapZs"
          : "https://docs.google.com/document/d/1lU7DxI45hIi3Yk8-SSge56yiHIey3-MW",
      title: t("motivational"),
    },
  ])

  useEffect(() => {
    i18next.on("languageChanged", () => {
      setFiles([
        {
          uri:
            i18next.language === "it"
              ? "https://docs.google.com/document/d/1Pl2NxVvkoOkvGs05AMeRs3qBxHuaXiqDUnAK8pGapZs"
              : "https://docs.google.com/document/d/1lU7DxI45hIi3Yk8-SSge56yiHIey3-MW",
          title: t("motivational"),
        },
      ])
    })

    return () => {
      i18next.off("languageChanged")
    }
  }, [])

  return (
    <PageContainer
      title={t("mail_flow_motivational")}
      breadcrumbs={[
        { title: t("operational_kit"), path: "/library" },
        { title: t("mail_flow_motivational") },
      ]}
      withFilters={false}
      withDatePicker={false}
      withExportButton={false}
      paddingTop={24}
    >
      <Title fontSize={14}>{t("mail_flow_description")}</Title>
      <Grid container spacing={3} columns={3} style={{ marginTop: 16 }}>
        {files.map((file, index) => (
          <Grid item xs={3} md={1} key={index}>
            <FadeFromTop delay={0.025 * index}>
              <ResourceCard file={file} />
            </FadeFromTop>
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  )
}

export default MailFlowMotivational
