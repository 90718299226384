import awLogo from "../../assets/images/aw-logo.svg"
import { LinearProgress } from "@mui/material"
import Center from "../../components/global/Center"
import { colors } from "../../services/config/colors"

const Loading = () => {
  return (
    <Center style={{ backgroundColor: colors.backgroundWhite }}>
      <img src={awLogo} style={{ width: "38%", maxWidth: 165 }} alt="AWorld" />
      <LinearProgress
        style={{
          width: "34%",
          maxWidth: 157,
          marginTop: 16,
          borderRadius: 100,
        }}
      />
    </Center>
  )
}

export default Loading
