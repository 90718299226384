import { useContext, useEffect } from "react"
import ChartDataItem from "../../models/chartDataItem"
import { randomNumberFromInterval } from "../../services/utils/utils"
import { colors } from "../../services/config/colors"
import { MainContext } from "../../controllers/main"
import { Skeleton } from "@mui/material"
import { defaultTransition } from "../../services/config/constants"

const ScatterTextChart = ({ data }: { data: ChartDataItem[] }) => {
  const { dataLoading } = useContext(MainContext)

  // create text elements for chart
  useEffect(() => {
    if (!dataLoading) {
      // sort data from lower to higher value
      data = data.sort((a, b) => {
        if (a.value < b.value) {
          return -1
        } else if (a.value > b.value) {
          return 1
        } else {
          return 0
        }
      })

      // define min and max font sizes
      const minFontSize = 10
      const maxFontSize = 26

      // find min and max data values
      const minValue = Math.min(...data.map((item) => item.value))
      const maxValue = Math.max(...data.map((item) => item.value))

      // calculate font size with linear scale and top and left positions, based on chart container dimensions
      // then add the element to the dom
      const container = document.getElementById("scatter-text-chart-container")!
      container.innerHTML = ""

      data.map((item, index) => {
        const existingItems = document.getElementsByClassName(
          "scatter-text-chart-item"
        )

        const fontSize =
          ((item.value - minValue) / (maxValue - minValue)) *
            (maxFontSize - minFontSize) +
          minFontSize

        let notOverlapping = true
        let numberOfIterations = 0
        do {
          notOverlapping = true

          const top = randomNumberFromInterval(0, container.clientHeight)
          const left = randomNumberFromInterval(0, container.clientWidth)

          const newItem = document.createElement("p")
          newItem.innerText = item.label as string
          newItem.className = "scatter-text-chart-item"
          newItem.style.fontWeight = "700"
          newItem.style.fontSize = fontSize + "px"
          newItem.style.position = "absolute"
          newItem.style.top = top + "px"
          newItem.style.left = left + "px"
          newItem.style.padding = "4px"
          newItem.style.paddingInline = "6px"
          newItem.style.borderRadius = "3px"
          newItem.style.color = item.color ?? colors.text
          if (
            (index === data.length - 1 ||
              index === data.length - 2 ||
              index === data.length - 3) &&
            item.backgroundColor
          ) {
            newItem.style.backgroundColor = item.backgroundColor
          }

          container.appendChild(newItem)

          // check if the new item go outside the container
          if (
            newItem.getBoundingClientRect().bottom >
              container.getBoundingClientRect().bottom ||
            newItem.getBoundingClientRect().right >
              container.getBoundingClientRect().right
          ) {
            notOverlapping = false
            container.removeChild(container.lastElementChild!)
          } else {
            // check if the new item collides with any other existing item
            for (let i = 0; i < existingItems.length - 1; i++) {
              if (
                newItem.getBoundingClientRect().top <
                  existingItems[i].getBoundingClientRect().bottom &&
                newItem.getBoundingClientRect().bottom >
                  existingItems[i].getBoundingClientRect().top &&
                newItem.getBoundingClientRect().left <
                  existingItems[i].getBoundingClientRect().right &&
                newItem.getBoundingClientRect().right >
                  existingItems[i].getBoundingClientRect().left
              ) {
                notOverlapping = false
              }
            }

            numberOfIterations++

            if (!notOverlapping && numberOfIterations < 100) {
              container.removeChild(container.lastElementChild!)
            }
          }
        } while (!notOverlapping && numberOfIterations < 100)
      })

      // animate all items
      const chartItems = document.getElementsByClassName(
        "scatter-text-chart-item"
      ) as HTMLCollectionOf<HTMLElement>
      for (let i = 0; i < chartItems.length; i++) {
        chartItems[i].style.scale = "0"
      }
      setTimeout(() => {
        for (let i = 0; i < chartItems.length; i++) {
          chartItems[i].style.transition = defaultTransition
          chartItems[i].style.scale = "1"
        }
      }, 1)
    }
  }, [data, dataLoading])

  return dataLoading ? (
    <Skeleton variant="rounded" style={{ width: "100%", height: "100%" }} />
  ) : (
    <div
      id="scatter-text-chart-container"
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    />
  )
}

export default ScatterTextChart
