import { Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { CSSProperties, ReactNode } from "react"
import { ErrorBoundary } from "react-error-boundary"
import CardFallback from "./CardFallback"

const Card = ({
  children,
  width,
  gap = 3,
  style,
}: {
  children?: ReactNode
  width?: string
  gap?: number
  style?: CSSProperties
}) => {
  return (
    <ErrorBoundary
      FallbackComponent={(props) => (
        <CardFallback props={props} width={width} style={style} />
      )}
    >
      <Stack
        gap={gap}
        style={{
          width: width ?? "100%",
          minWidth: width ?? "100%",
          height: "auto",
          borderRadius: 16,
          backgroundColor: colors.backgroundWhite,
          padding: 24,
          position: "relative",
          ...style,
        }}
      >
        {children}
      </Stack>
    </ErrorBoundary>
  )
}

export default Card
